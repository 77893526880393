export var AudioMode;
(function (AudioMode) {
    AudioMode["Track2D"] = "Track2D";
    AudioMode["Track3D"] = "Track3D";
})(AudioMode || (AudioMode = {}));
export var AudioType;
(function (AudioType) {
    // Interface
    AudioType["Error"] = "Error";
    AudioType["Click"] = "Click";
    // Battle
    AudioType["Win"] = "Win";
    AudioType["GameOver"] = "GameOver";
    AudioType["Join"] = "Join";
    // Wave
    AudioType["WaveStart"] = "WaveStart";
    AudioType["WaveComplete"] = "WaveComplete";
    AudioType["WaveTick"] = "WaveTick";
    // Builder
    AudioType["Build"] = "Build";
    // Buildings
    AudioType["BuildingHit"] = "BuildingHit";
    AudioType["BuildingUpgrade"] = "BuildingUpgrade";
    AudioType["BuildingRepair"] = "BuildingRepair";
    AudioType["BuildingBreak"] = "BuildingBreak";
    // Player
    AudioType["UseSkill"] = "UseSkill";
    AudioType["Step"] = "Step";
    AudioType["LevelUp"] = "LevelUp";
    AudioType["Upgrade"] = "Upgrade";
    AudioType["Attack"] = "Attack";
    // Crystal
    AudioType["Pickup"] = "Pickup";
    // Mobs
    AudioType["MobFrost"] = "MobFrost";
    AudioType["MobGore"] = "MobGore";
    AudioType["MobExtraAttack"] = "MobExtraAttack";
    AudioType["MobBossRoar"] = "MobBossRoar";
    // Shots
    AudioType["ShotFrost"] = "ShotFrost";
    AudioType["ShotLaser"] = "ShotLaser";
    AudioType["ShotFire"] = "ShotFire";
    AudioType["ShotElectro"] = "ShotElectro";
    AudioType["ShotSimple"] = "ShotSimple";
    // Units
    AudioType["UnitHitFire"] = "UnitHitFire";
    AudioType["UnitHitBite"] = "UnitHitBite";
    AudioType["UnitHitPunch"] = "UnitHitPunch";
    // Terrain
    AudioType["Ambient"] = "Ambient";
})(AudioType || (AudioType = {}));
