import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { INVENTORY_ITEM_ICON } from '../../const';
import { useInventorySlot } from '../../hooks/use-inventory-slot';
import { InventorySelectionState } from '../../state';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import styles from './styles.module.scss';
export const InventorySlot = ({ type, slot }) => {
    const language = useLanguage();
    const item = useInventorySlot(type, slot);
    const [, setInventorySelection] = useRecoilState(InventorySelectionState);
    const name = item && language(`${item}Name`);
    const description = item && language(`${item}Description`);
    const handleClickSlot = useCallback(() => {
        setInventorySelection({ type, slot });
    }, []);
    return (React.createElement(Interactive, { className: styles.wrapper, onClick: handleClickSlot },
        React.createElement("div", { className: cn(styles.frame, {
                [styles.empty]: !item,
            }) }, item ? (React.createElement(Icon, { type: INVENTORY_ITEM_ICON[item], className: styles.icon })) : (React.createElement("div", { className: styles.dot }))),
        React.createElement(Section, { direction: "vertical", gap: 8 }, item ? (React.createElement(React.Fragment, null,
            React.createElement(Text, { view: "primary", size: "l", bold: true, uppercase: true }, name),
            React.createElement(Text, { view: "secondary", size: "s" }, description))) : (React.createElement(Text, { view: "transparent", size: "l", bold: true, uppercase: true }, language('EmptySlot'))))));
};
