import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { ActiveTutorialStepsState } from '~/client/battle/tutorial/ui/state';
import { useSelfPlayer } from '~/client/battle/ui/hooks/use-self-player';
import { Interactive } from '~/client/core/ui/components/interactive';
import { TutorialStep } from '~/shared/battle/tutorial/types';
import styles from './styles.module.scss';
import IconAttack from './icons/attack.svg';
export const ButtonAttack = () => {
    const player = useSelfPlayer();
    const activeTutorialSteps = useRecoilValue(ActiveTutorialStepsState);
    const blink = activeTutorialSteps.has(TutorialStep.Attack);
    const handleClick = useCallback(() => {
        player.attack();
    }, []);
    return (React.createElement(Interactive, { onClick: handleClick, className: cn(styles.button, {
            [styles.blink]: blink,
        }) },
        React.createElement(IconAttack, { className: styles.icon })));
};
