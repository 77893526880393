import React from 'react';
import { ArmourBar } from './components/armour-bar';
import { Indicators } from './components/indicators';
import { MobContext } from './context';
import { EntityPanel } from '~/client/battle/entity/ui/components/entity-panel';
import { HealthBar } from '~/client/battle/entity/ui/components/health-bar';
export const MobUI = ({ target }) => (React.createElement(MobContext.Provider, { value: target },
    React.createElement(EntityPanel, { renderItem: target.renderItem, indicators: React.createElement(Indicators, null), bars: React.createElement(React.Fragment, null,
            React.createElement(ArmourBar, null),
            React.createElement(HealthBar, { unit: target })) })));
