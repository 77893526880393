import React from 'react';
import { useRecoilValue } from 'recoil';
import { InventoryCategory } from './category';
import { InventorySelection } from './selection';
import { InventorySelectionState } from './state';
import { useUserData } from '../../../hooks/use-user-data';
import { Scrollbar } from '~/client/core/ui/components/scrollbar';
import { SKILL_MAX_SLOTS } from '~/shared/battle/entity/unit/player/skill/const';
import { InventoryItemType } from '~/shared/core/inventory/item/types';
import styles from './styles.module.scss';
export const PageInventory = () => {
    const inventorySelection = useRecoilValue(InventorySelectionState);
    const skillSlots = useUserData('skillSlots');
    return (React.createElement("div", { className: styles.wrapper }, inventorySelection ? (React.createElement(InventorySelection, null)) : (React.createElement(Scrollbar, null,
        React.createElement("div", { className: styles.categories },
            React.createElement(InventoryCategory, { type: InventoryItemType.Droid, maxSlots: 1, availableSlots: 1 }),
            React.createElement(InventoryCategory, { type: InventoryItemType.Skill, maxSlots: SKILL_MAX_SLOTS, availableSlots: skillSlots }))))));
};
