import cn from 'classnames';
import React, { useCallback } from 'react';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Interactive } from '~/client/core/ui/components/interactive';
import { useUserData } from '~/client/relay/ui/hooks/use-user-data';
import { SKILL_SLOT_COST } from '~/shared/battle/entity/unit/player/skill/const';
import { InventoryRequest } from '~/shared/core/inventory/types';
import styles from './styles.module.scss';
import IconLock from './icons/lock.svg';
export const InventoryBuySlot = () => {
    const room = useRoom();
    const language = useLanguage();
    const crystals = useUserData('crystals');
    const allow = crystals >= SKILL_SLOT_COST;
    const handleClickBuy = useCallback(() => {
        room.sendRequest(InventoryRequest.BuySlot);
    }, []);
    return (React.createElement(Interactive, { disabled: !allow, onClick: handleClickBuy, className: cn(styles.wrapper, {
            [styles.allow]: allow,
        }) },
        React.createElement("div", { className: styles.frame },
            React.createElement(IconLock, { className: styles.icon })),
        React.createElement("div", { className: styles.info },
            React.createElement("div", { className: styles.label }, language('ExtraSlot')),
            React.createElement(Crystals, { value: SKILL_SLOT_COST }))));
};
