import { Mob } from '../..';
import { ModelType } from '~/client/core/assets/types';
import './resources';
export class MobCrab extends Mob {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.MobCrab,
        }, schema);
    }
}
