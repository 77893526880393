import { AudioType } from '~/client/core/audio/types';
import { Messages } from '~/client/core/messages';
import { WaveMessage } from '~/shared/battle/wave/types';
import './resources';
export class Wave {
    constructor(battle) {
        this.battle = battle;
        this.schema = battle.state.wave;
        this.messages = new Messages(this.battle.origin, this.battle.messagesBuffer);
        this.messages.on(WaveMessage.Start, () => {
            this.battle.scene.audio.play2D(AudioType.WaveStart);
        });
        this.messages.on(WaveMessage.Complete, () => {
            this.battle.scene.audio.play2D(AudioType.WaveComplete);
        });
    }
    skipTimeleft() {
        this.messages.send(WaveMessage.SkipTimeleft, void {});
    }
}
