import { BuildingVariant } from '../entity/building/types';
import { UPGRADE_MAX_LEVEL } from '../entity/unit/player/upgrades/const';
import { BattleMode } from '../types';
import { Progression } from '~/shared/core/progression';
export const BUILDER_RADIUS = new Progression({
    defaultValue: 3.0,
    maxValue: 5.0,
    maxLevel: UPGRADE_MAX_LEVEL,
});
export const BUILDER_RESTRICTION_LEVEL_GRADE = {
    [BattleMode.Online]: 3,
    [BattleMode.Single]: 4,
};
export const BUILDER_FLOW = {
    [BattleMode.Single]: [
        BuildingVariant.TowerFire,
        BuildingVariant.Generator,
    ],
    [BattleMode.Online]: [
        BuildingVariant.TowerFire,
        BuildingVariant.Generator,
        BuildingVariant.SpawnerSmall,
    ],
};
