import cn from 'classnames';
import React, { useCallback } from 'react';
import { useBuilding } from '../../../../hooks/use-building';
import { Cost } from '~/client/battle/ui/components/cost';
import { useSelfPlayerSchema } from '~/client/battle/ui/hooks/use-self-player-schema';
import { Device } from '~/client/core/device';
import { KeyboardKey } from '~/client/core/input/keyboard/ui/components/key';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { BuildingUtils } from '~/shared/battle/entity/building/utils';
import { DeviceType } from '~/shared/core/device/types';
import styles from './styles.module.scss';
import IconLock from './icons/lock.svg';
export const Control = ({ icon, message, hotkey, cost, disabled, minLevel, blink, }) => {
    const battle = useRoom();
    const building = useBuilding();
    const player = useSelfPlayerSchema();
    const hasLevel = (!minLevel ||
        BuildingUtils.hasLevelToUpgrade(building.schema, battle.state, player));
    const handleClick = useCallback(() => {
        if (!building.disposed) {
            building.doAction(message);
        }
    }, []);
    return (React.createElement(Interactive, { onClick: handleClick, disabled: disabled || !hasLevel, className: cn(styles.container, {
            [styles.disabled]: disabled || !hasLevel,
            [styles.blink]: blink,
        }) },
        disabled ? (React.createElement(Icon, { type: icon, className: styles.icon })) : (React.createElement(Section, { direction: 'vertical', align: 'center', gap: 12 }, hasLevel ? (React.createElement(React.Fragment, null,
            React.createElement(Icon, { type: icon, className: styles.icon }),
            !!cost && (React.createElement("div", { className: styles.addon },
                React.createElement(Cost, { amount: cost }))))) : (React.createElement(React.Fragment, null,
            React.createElement(IconLock, null),
            React.createElement("div", { className: styles.minLevel },
                React.createElement("b", null, minLevel),
                " LVL"))))),
        Device.type === DeviceType.Desktop && hotkey && (React.createElement("div", { className: styles.hotkey },
            React.createElement(KeyboardKey, { size: 's' }, hotkey.replace('Key', ''))))));
};
