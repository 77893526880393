var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect, useState } from 'react';
import { useOpponentPlayerSchema } from '../../../hooks/use-opponent-player-schema';
import { useScreen } from '../../../hooks/use-screen';
import { useSelfPlayerSchema } from '../../../hooks/use-self-player-schema';
import { Button } from '../../button';
import { Client } from '~/client/core/client';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { SDK } from '~/client/core/sdk';
import { SDKAdType } from '~/client/core/sdk/types';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Score } from '~/client/core/ui/components/amount/score';
import { Section } from '~/client/core/ui/components/section';
import { BattleMode } from '~/shared/battle/types';
import { RoomType } from '~/shared/core/room/types';
import styles from './styles.module.scss';
import IconAds from './icons/ads.svg';
export const StageFinished = () => {
    const battle = useRoom();
    const language = useLanguage();
    const { closeScreen } = useScreen();
    const player = useSelfPlayerSchema();
    const score = useSchemaValue(player, 'score');
    const crystals = useSchemaValue(player, 'crystals');
    const opponentPlayer = useOpponentPlayerSchema();
    const opponentConnected = useSchemaValue(opponentPlayer, 'connected');
    const [waitingOpponent, setWaitingOpponent] = useState(false);
    const winner = battle.state.winnerId === player.id;
    const loseText = battle.state.mode === BattleMode.Online
        ? language('YouLose')
        : language('YouDied');
    const canLoadSave = (battle.state.mode === BattleMode.Single);
    const canPlayAgain = (battle.state.mode === BattleMode.Single ||
        (opponentConnected && !waitingOpponent));
    const handleClickPlayAgain = () => {
        battle.restart(false);
        if (battle.state.mode === BattleMode.Online) {
            setWaitingOpponent(true);
        }
        else {
            Client.setLoading();
        }
    };
    const handleClickLoadSave = () => __awaiter(void 0, void 0, void 0, function* () {
        yield SDK.showAd(SDKAdType.Rewarded);
        battle.restart(true);
        Client.setLoading();
    });
    const handleClickMenu = () => {
        Client.joinRoomByType(RoomType.Relay);
    };
    useEffect(() => {
        closeScreen();
    }, []);
    return (React.createElement("div", { className: cn(styles.screen, {
            [styles.winner]: winner,
        }) },
        React.createElement(Section, { direction: "vertical", align: "center", gap: 10 },
            React.createElement("div", { className: styles.label }, winner ? language('YouWin') : loseText),
            React.createElement(Section, { direction: "vertical", align: "center", gap: 48 },
                React.createElement(Section, { direction: "horizontal", gap: 8 },
                    React.createElement("div", { className: styles.reward },
                        React.createElement(Crystals, { value: crystals, size: 'l', animated: true })),
                    React.createElement("div", { className: styles.reward },
                        React.createElement(Score, { value: score, size: 'l', animated: true }))),
                React.createElement(Section, { direction: "vertical", align: 'center', gap: 4 },
                    battle.state.mode === BattleMode.Online && (opponentConnected ? (waitingOpponent && (React.createElement("div", { className: styles.status }, language('WaitingOpponent')))) : (React.createElement("div", { className: styles.status }, language('OpponentDisconnected')))),
                    React.createElement(Section, { direction: "vertical", align: "center", gap: 16, className: styles.buttons },
                        canLoadSave && (React.createElement(Button, { onClick: handleClickLoadSave, size: "large", view: "accent", icon: SDK.enabled ? IconAds : undefined }, language('LoadSave'))),
                        React.createElement(Section, { direction: "vertical", gap: 4 },
                            canPlayAgain && (React.createElement(Button, { onClick: handleClickPlayAgain, view: canLoadSave ? 'default' : 'accent' }, language('PlayAgain'))),
                            React.createElement(Button, { onClick: handleClickMenu }, language('MainMenu')))))))));
};
