import { BUILDING_MODELS, BUILDING_MODEL_UPGRADE_PREFIX } from '../../entity/building/const';
import { SceneLayer } from '../../scene/types';
import { MaterialType } from '~/client/core/assets/materials/types';
import { Logger } from '~/client/core/logger';
import { Model } from '~/client/core/render-item/model';
export class BuildingPreview extends Model {
    constructor(battle, variant) {
        super(battle.scene, {
            model: BUILDING_MODELS[variant],
            material: MaterialType.Building,
        });
        this.eachMeshes((mesh) => {
            mesh.layers.set(SceneLayer.Misc);
        });
        const root = this.getRoot();
        if (!root) {
            Logger.warn(`Invalid root node of model for building '${variant}'`);
            return;
        }
        root.children.forEach((object) => {
            if (object.name.indexOf(BUILDING_MODEL_UPGRADE_PREFIX) === 0) {
                object.visible = false;
            }
        });
    }
}
