import { InputTouchChannel } from './channel';
import { INPUT_TOUCH_MIN_SWIPE_DISTANCE } from './const';
import { InputTouchSwipeDirection } from './types';
import { Input } from '..';
import { EventStream } from '~/shared/core/event-stream';
export class InputTouch {
    static addListeners() {
        this.listenTouchStart();
        this.listenTouchEnd();
        this.listenTouchMove();
    }
    static getFreeTouch() {
        var _a;
        const list = Array.from(this.channels.values()).reverse();
        return (_a = list.find((touch) => !touch.taken)) !== null && _a !== void 0 ? _a : null;
    }
    static listenTouchStart() {
        document.addEventListener('touchstart', (event) => {
            if (!Input.isNativeTarget(event)) {
                event.preventDefault();
            }
            const isWorldTarget = Input.isWorldTarget(event);
            Array.from(event.changedTouches).forEach((touch) => {
                const channel = new InputTouchChannel({ touch, event });
                this.channels.set(touch.identifier, channel);
                this.events.onTouch.invoke(channel);
                if (isWorldTarget) {
                    this.events.onTouchWorld.invoke(channel);
                }
            });
        }, {
            passive: false,
        });
    }
    static listenTouchEnd() {
        document.addEventListener('touchend', (event) => {
            if (!Input.isNativeTarget(event)) {
                event.preventDefault();
            }
            const touch = this.getFreeTouch();
            if (touch) {
                this.handleTouchSwipe(touch);
            }
            Array.from(event.changedTouches).forEach((touch) => {
                const channel = this.channels.get(touch.identifier);
                if (channel) {
                    channel.events.onRelease.invoke();
                    this.channels.delete(touch.identifier);
                }
            });
        });
    }
    static listenTouchMove() {
        document.addEventListener('touchmove', (event) => {
            if (!Input.isNativeTarget(event)) {
                event.preventDefault();
            }
            Array.from(event.touches).forEach((touch) => {
                const channel = this.channels.get(touch.identifier);
                if (channel) {
                    channel.updatePosition(touch);
                    channel.checkShifting();
                    channel.events.onMove.invoke();
                }
            });
        });
    }
    static handleTouchSwipe(touch) {
        const shift = touch.beginPosition.clone().sub(touch.position);
        if (shift.length() < INPUT_TOUCH_MIN_SWIPE_DISTANCE) {
            return;
        }
        let direction;
        if (Math.abs(shift.x) > Math.abs(shift.y)) {
            if (shift.x > 0) {
                direction = InputTouchSwipeDirection.Left;
            }
            else {
                direction = InputTouchSwipeDirection.Right;
            }
        }
        else {
            if (shift.y > 0) {
                direction = InputTouchSwipeDirection.Up;
            }
            else {
                direction = InputTouchSwipeDirection.Down;
            }
        }
        this.events.onTouchSwipe.invoke({
            position: touch.beginPosition,
            direction,
        });
    }
}
InputTouch.channels = new Map();
InputTouch.events = {
    onTouch: new EventStream(),
    onTouchWorld: new EventStream(),
    onTouchSwipe: new EventStream(),
};
