import { PerspectiveCamera, Matrix4, Vector3 } from 'three';
import { CAMERA_FOV, CAMERA_SHIFT_MULTIPLIER, CAMERA_TARGET_SCREEN_HEIGHT } from './const';
import { Device } from '~/client/core/device';
export class Camera extends PerspectiveCamera {
    constructor() {
        super(CAMERA_FOV);
        this.target = null;
        this.targetOffset = new Vector3();
        this.angle = 0;
        this.rotationMatrix = new Matrix4();
        this.distance = 0;
        this.layers.enableAll();
    }
    setSize(size) {
        this.aspect = size.x / size.y;
        this.updateProjectionMatrix();
    }
    setTarget(target) {
        this.target = target;
    }
    setTargetOffset(offset) {
        this.targetOffset.copy({
            x: offset,
            y: 0,
            z: offset,
        });
    }
    setAngle(angle) {
        this.angle = angle;
        this.rotationMatrix = new Matrix4().makeRotationY(-angle);
    }
    setDistance(distance) {
        this.distance = distance;
    }
    getShift(position) {
        var _a, _b;
        const multiplier = (1 / this.distance) * CAMERA_SHIFT_MULTIPLIER;
        return position.clone()
            .sub((_b = (_a = this.target) === null || _a === void 0 ? void 0 : _a.position) !== null && _b !== void 0 ? _b : this.position)
            .applyMatrix4(this.rotationMatrix)
            .multiplyScalar(multiplier);
    }
    update() {
        if (!this.target) {
            return;
        }
        const screenSize = Device.getScreenSize();
        const zoom = Math.sqrt(screenSize.y / CAMERA_TARGET_SCREEN_HEIGHT);
        const distance = this.distance * Math.min(1.0, zoom);
        this.position
            .copy(this.target.position)
            .add({
            x: distance * Math.sin(this.angle),
            y: distance,
            z: distance * Math.cos(this.angle),
        });
        const targetPosition = this.target.position.clone().add(this.targetOffset);
        this.lookAt(targetPosition);
    }
}
