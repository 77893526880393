import React from 'react';
import { Key } from './key';
import { KeyAction } from './key/types';
import { Swipe } from './swipe';
import { SwipeDirection } from './swipe/types';
import { Text } from './text';
import { Device } from '~/client/core/device';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { TutorialStep } from '~/shared/battle/tutorial/types';
import { DeviceType } from '~/shared/core/device/types';
export const TutorialContent = ({ step }) => {
    const language = useLanguage();
    const text = step && language(`Hint${step}`);
    switch (step) {
        case TutorialStep.Attack:
            return Device.type === DeviceType.Desktop && (React.createElement(Key, { action: KeyAction.Press, target: 'SPACE' }, text));
        case TutorialStep.UpgradeGenerator:
        case TutorialStep.UpgradeTowerFire:
            return (React.createElement(Text, null, text));
        case TutorialStep.UpgradePlayer:
            return Device.type === DeviceType.Mobile ? (React.createElement(Swipe, { direction: SwipeDirection.Up }, text)) : (React.createElement(Key, { action: KeyAction.Hold, target: Device.isMacOS ? 'OPTION' : 'ALT' }, text));
        case TutorialStep.BuildSpawnerSmall:
        case TutorialStep.BuildAmmunition:
        case TutorialStep.BuildTowerFire:
        case TutorialStep.BuildTowerFrost:
        case TutorialStep.BuildGenerator:
        case TutorialStep.BuildSecondGenerator:
        case TutorialStep.BuildRadar:
            return Device.type === DeviceType.Mobile ? (React.createElement(Swipe, { direction: SwipeDirection.Left }, text)) : (React.createElement(Key, { action: KeyAction.Hold, target: 'SHIFT' }, text));
    }
};
