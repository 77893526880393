import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { OverlayState } from '../../overlays/state';
import { OverlayType } from '../../overlays/types';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
import IconLogin from './icons/login.svg';
export const LoginButton = () => {
    const [, setOverlay] = useRecoilState(OverlayState);
    const language = useLanguage();
    const handleClick = useCallback(() => {
        setOverlay(OverlayType.Auth);
    }, []);
    return (React.createElement(Interactive, { onClick: handleClick, className: cn(styles.container, styles.primary) },
        React.createElement(Icon, { type: IconLogin, className: styles.icon }),
        React.createElement("div", { className: styles.label }, language('LogIn'))));
};
