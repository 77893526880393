import { DROID_ICON } from '~/client/battle/entity/unit/npc/droid/ui/components/droid-icon/const';
import { SKILL_ICON } from '~/client/battle/entity/unit/player/ui/components/skill-icon/const';
import { DROIDS_SHARED_CONFIG } from '~/shared/battle/entity/unit/npc/droid/const';
import { SKILLS_SHARED_CONFIG } from '~/shared/battle/entity/unit/player/skill/const';
import { InventoryItemType } from '~/shared/core/inventory/item/types';
export const INVENTORY_ITEM_ICON = Object.assign(Object.assign({}, DROID_ICON), SKILL_ICON);
export const INVENTORY_CATEGORY_ITEMS = {
    [InventoryItemType.Droid]: Object.keys(DROIDS_SHARED_CONFIG),
    [InventoryItemType.Skill]: Object.keys(SKILLS_SHARED_CONFIG),
};
