import React from 'react';
import { Button } from '../../../../button';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
export const Confirmation = ({ text, onConfirm, onDecline, }) => {
    const language = useLanguage();
    return (React.createElement(Section, { direction: "vertical", gap: 24, align: "center" },
        React.createElement(Text, { size: "l", bold: true }, text),
        React.createElement(Section, { direction: "horizontal", gap: 16 },
            React.createElement(Button, { onClick: onConfirm, view: "accent" }, language('Yes')),
            React.createElement(Button, { onClick: onDecline, view: "negative" }, language('No')))));
};
