import React from 'react';
import { InventoryBuySlot } from './buy-slot';
import { InventorySlot } from './slot';
import { InventoryPanel } from '../panel';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
export const InventoryCategory = ({ type, maxSlots, availableSlots }) => {
    const language = useLanguage();
    const lockedSlots = maxSlots - availableSlots;
    return (React.createElement(InventoryPanel, { title: language(`Category${type}`) },
        Array(availableSlots).fill(null).map((_, slot) => (React.createElement(InventorySlot, { key: slot, type: type, slot: slot }))),
        Array(lockedSlots).fill(null).map((_, slot) => (React.createElement(InventoryBuySlot, { key: slot })))));
};
