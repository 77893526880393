var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SDK_DOMAINS, SDK_SCRIPT } from './const';
import { SDKAdType } from './types';
import { Logger } from '../logger';
import { Utils } from '~/shared/core/utils';
export class SDK {
    static showAd(type) {
        return new Promise((resolve) => {
            this.activeAd = true;
            const complete = (state) => {
                this.activeAd = false;
                resolve(state);
            };
            try {
                if (this.enabled) {
                    if (type === SDKAdType.Rewarded) {
                        window.PokiSDK.rewardedBreak().then((state) => {
                            complete(state);
                        });
                    }
                    else {
                        window.PokiSDK.commercialBreak().then(() => {
                            complete(false);
                        });
                    }
                }
                else {
                    if (type === SDKAdType.Rewarded) {
                        Logger.debug('SDK | Rewarded ad has been showed');
                        complete(true);
                    }
                    else {
                        Logger.debug('SDK | Commercial ad has been showed');
                        complete(false);
                    }
                }
            }
            catch (error) {
                // Logger.error('Failed to show ads', error as TypeError);
                complete(false);
            }
        });
    }
    static completeLoading() {
        var _a;
        try {
            if (this.enabled) {
                (_a = window.PokiSDK) === null || _a === void 0 ? void 0 : _a.gameLoadingFinished();
            }
            else {
                Logger.debug('SDK | Game loading has been finished');
            }
        }
        catch (error) {
            // Logger.error('Failed to complete loading', error as TypeError);
        }
    }
    static togglePlaying(state) {
        var _a, _b;
        if (this.playing === state) {
            return;
        }
        this.playing = state;
        try {
            if (this.enabled) {
                if (state) {
                    (_a = window.PokiSDK) === null || _a === void 0 ? void 0 : _a.gameplayStart();
                }
                else {
                    (_b = window.PokiSDK) === null || _b === void 0 ? void 0 : _b.gameplayStop();
                }
            }
            else {
                if (state) {
                    Logger.debug('SDK | Game has been started');
                }
                else {
                    Logger.debug('SDK | Game has been stopped');
                }
            }
        }
        catch (error) {
            // Logger.error('Failed to toggle play state', error as TypeError);
        }
    }
    static load() {
        return __awaiter(this, void 0, void 0, function* () {
            const useSDK = SDK_DOMAINS.some((domain) => Utils.matchDomain(domain, location.origin));
            if (!useSDK) {
                return Promise.resolve();
            }
            Logger.debug('SDK | Starting to load script');
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.setAttribute('src', SDK_SCRIPT);
                script.addEventListener('load', resolve);
                script.addEventListener('error', reject);
                document.body.appendChild(script);
            })
                .then(() => {
                var _a;
                (_a = window.PokiSDK) === null || _a === void 0 ? void 0 : _a.init();
                this.enabled = true;
                Logger.debug('SDK | Script has been loaded');
            })
                .catch((error) => {
                Logger.error('SDK | Failed to load script', error);
            });
        });
    }
}
SDK.activeAd = false;
SDK.enabled = false;
SDK.playing = false;
