import React, { useMemo } from 'react';
import { CATEGORY_ICON } from './const';
import { Variant } from './variant';
import { CardsList } from '~/client/battle/ui/components/cards-list';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { UpgradeVariant } from '~/shared/battle/entity/unit/player/upgrades/types';
export const Category = ({ category }) => {
    const language = useLanguage();
    const upgrades = useMemo(() => (Object.values(UpgradeVariant).filter((variant) => variant.indexOf(category) === 0)), [category]);
    return (React.createElement(CardsList, { icon: CATEGORY_ICON[category], label: language(category) }, upgrades.map((variant) => (React.createElement(Variant, { key: variant, variant: variant })))));
};
