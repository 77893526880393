import { useEffect, useRef } from 'react';
export function useNextEffect(callback, depends) {
    const refSkip = useRef(true);
    useEffect(() => {
        if (refSkip.current) {
            refSkip.current = false;
            return;
        }
        callback();
    }, depends);
}
