import React from 'react';
import { Indicators } from './components/indicators';
import { LevelLabel } from './components/level-label';
import { SkillLabels } from './components/skill-labels';
import { StaminaBar } from './components/stamina-bar';
import { PlayerContext } from './context';
import { EntityPanel } from '../../../ui/components/entity-panel';
import { HealthBar } from '../../../ui/components/health-bar';
import { Client } from '~/client/core/client';
import { Section } from '~/client/core/ui/components/section';
import styles from './styles.module.scss';
export const PlayerUI = ({ target }) => {
    var _a;
    return (React.createElement(PlayerContext.Provider, { value: target },
        React.createElement(EntityPanel, { renderItem: target.renderItem, indicators: React.createElement(Indicators, null), meta: React.createElement(Section, { direction: "vertical", align: "center", gap: 8 },
                target.schema.id !== Client.sessionId && (React.createElement(SkillLabels, null)),
                React.createElement(Section, { direction: "horizontal", align: "center", gap: 4 },
                    React.createElement(LevelLabel, null),
                    ((_a = target.schema.user) === null || _a === void 0 ? void 0 : _a.name) && (React.createElement("div", null, target.schema.user.name)))), bars: React.createElement("div", { className: styles.bars },
                React.createElement(HealthBar, { unit: target }),
                React.createElement(StaminaBar, null)) })));
};
