import { AmbientLight, Box3, DirectionalLight, Mesh, PerspectiveCamera, Scene, Vector3, WebGLRenderer } from 'three';
import { Assets } from '../assets';
export class Snap extends Scene {
    constructor({ width, height, camera }) {
        super();
        this.renderer = new WebGLRenderer({
            alpha: true,
            antialias: true,
        });
        this.renderer.setSize(width, height);
        this.renderer.setPixelRatio(window.devicePixelRatio || 1);
        this.camera = new PerspectiveCamera(16);
        this.camera.aspect = width / height;
        this.camera.position.copy(camera);
        const ambientLight = new AmbientLight(0xffffff);
        this.add(ambientLight);
        const directionalLight = new DirectionalLight(0xffffff, 1.0);
        directionalLight.position.set(0, 5, 2);
        this.add(directionalLight);
    }
    addModel({ model, material }) {
        const object = Assets.getClonedModel(model);
        this.add(object);
        const center = new Vector3();
        const boundingBox = new Box3().setFromObject(object);
        boundingBox.getSize(center);
        center.divideScalar(2);
        center.setX(0);
        center.setZ(0);
        this.camera.lookAt(center);
        this.camera.updateProjectionMatrix();
        object.traverse((mesh) => {
            if (mesh instanceof Mesh) {
                mesh.material = Assets.getMaterial(material);
            }
        });
        return object;
    }
    export() {
        this.renderer.render(this, this.camera);
        return this.renderer.domElement.toDataURL('image/png');
    }
}
