import { PlaneGeometry } from 'three';
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { MeshSetChild } from '~/client/core/render-item/mesh-set/child';
import { TILE_FACES } from '~/shared/battle/terrain/tile/const';
import './resources';
export class Tile extends MeshSetChild {
    constructor(schema) {
        super({
            position: schema.position,
        });
    }
    static makeGeometry(indexes) {
        const faces = indexes.map((index) => this.makeFace(index));
        return mergeGeometries(faces);
    }
    static makeFace(index) {
        const { p, r } = TILE_FACES[index];
        const face = new PlaneGeometry();
        face.rotateX(r.x);
        face.rotateY(r.y);
        face.rotateZ(r.z);
        face.translate(p.x, p.y, p.z);
        return face;
    }
    static getGroupKey(schema) {
        const indexes = schema.visibleIndexes;
        if (indexes.length === 0) {
            return null;
        }
        return `${schema.biome}|${schema.index}|${indexes.join('-')}`;
    }
}
