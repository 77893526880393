import { Assets } from '~/client/core/assets';
import { AudioType, AudioMode } from '~/client/core/audio/types';
import audioComplete from './complete.mp3';
import audioStart from './start.mp3';
import audioTick from './tick.mp3';
Assets.addAudio(AudioType.WaveTick, {
    path: audioTick,
    mode: AudioMode.Track2D,
    volume: 0.5,
});
Assets.addAudio(AudioType.WaveStart, {
    path: audioStart,
    mode: AudioMode.Track2D,
    volume: 0.4,
});
Assets.addAudio(AudioType.WaveComplete, {
    path: audioComplete,
    mode: AudioMode.Track2D,
    volume: 0.7,
});
