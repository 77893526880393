import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useEvent } from '~/client/core/event-steam/ui/hooks/use-event';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { useError } from '~/client/core/ui/hooks/use-error';
import { RelayRequest } from '~/shared/relay/types';
import styles from './styles.module.scss';
import IconJoin from './icons/join.svg';
export const WithFriend = () => {
    const room = useRoom();
    const language = useLanguage();
    const [id, setId] = useState('');
    const [yourId, setYourId] = useState('');
    const error = useError();
    const handleJoin = useCallback(() => {
        if (id) {
            room.sendRequest(RelayRequest.JoinFriendId, { id }).catch(() => {
                error.show();
            });
        }
        else {
            error.show();
        }
    }, [id]);
    const handleChangeId = (event) => {
        setId(event.target.value);
        error.hide();
    };
    useEvent(InputKeyboard.events.onKeyDown, (event) => {
        if (event.code === 'Enter') {
            handleJoin();
        }
    }, [handleJoin]);
    useEffect(() => {
        room.sendRequest(RelayRequest.GenerateFriendId).then(setYourId);
        return () => {
            room.sendRequest(RelayRequest.ClearFriendId);
        };
    }, []);
    return (React.createElement(Section, { direction: "vertical", align: "center", gap: 20 },
        React.createElement("div", { className: styles.yourId },
            language('YourId'),
            " ",
            React.createElement("b", null, yourId)),
        React.createElement("div", { className: cn(styles.form, {
                [styles.error]: error.active,
            }) },
            React.createElement("input", { type: "number", inputMode: "numeric", placeholder: language('FriendId'), onChange: handleChangeId, value: id, maxLength: 8 }),
            React.createElement(Interactive, { onClick: handleJoin, className: styles.button },
                React.createElement(IconJoin, null)))));
};
