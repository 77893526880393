import { Building } from '../..';
import { ShotFactory } from '~/client/battle/entity/shot/factory';
export class BuildingTower extends Building {
    constructor(battle, config, schema) {
        super(battle, config, schema);
        this.shot = ShotFactory.create(battle, schema.weapon.shot);
    }
    destroy() {
        this.shot.destroy();
        super.destroy();
    }
}
