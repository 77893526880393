import React, { useCallback, useState } from 'react';
import { Button } from '../button';
import { ErrorPlate } from '../error-plate';
import { InputPassword } from '../inputs/password';
import { InputUsername } from '../inputs/username';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { Client } from '~/client/core/client';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Checkbox } from '~/client/core/ui/components/checkbox';
import { Section } from '~/client/core/ui/components/section';
import { useError } from '~/client/core/ui/hooks/use-error';
import { UserAuthRequest } from '~/shared/core/user/auth/types';
export const AuthSingUp = () => {
    const room = useRoom();
    const audio = useAudio();
    const error = useError();
    const language = useLanguage();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [acceptPolicy, setAcceptPolicy] = useState(false);
    const handleSubmit = useCallback(() => {
        room.sendRequest(UserAuthRequest.Register, { username, password })
            .then((token) => {
            Client.setAuthToken(token);
            room.remountInterface();
        })
            .catch(({ message }) => {
            audio.play(AudioType.Error);
            error.show(language(message));
        });
    }, [username, password]);
    return (React.createElement("form", { onSubmit: handleSubmit, autoComplete: "false" },
        React.createElement(Section, { direction: "vertical", gap: 48 },
            React.createElement(Section, { direction: "vertical", gap: 24 },
                React.createElement(Section, { direction: "vertical", gap: 8 },
                    React.createElement(ErrorPlate, { error: error }),
                    React.createElement(InputUsername, { onChange: setUsername }),
                    React.createElement(InputPassword, { onChange: setPassword })),
                React.createElement(Checkbox, { checked: acceptPolicy, onChange: setAcceptPolicy },
                    language('AcceptTerms'),
                    React.createElement("br", null),
                    React.createElement("a", { href: "https://izowave.io/policy", target: "_blank" }, language('PrivacyPolicy')))),
            React.createElement(Section, { direction: "horizontal", gap: 16 },
                React.createElement(Button, { label: language('ToSignUp'), onClick: handleSubmit, disabled: !username || !password || !acceptPolicy })))));
};
