import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect } from 'react';
import { formatTime } from './utils/format-time';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
import IconPause from './icons/pause.svg';
import IconSkip from './icons/skip.svg';
export const WaveInfoPanel = () => {
    const { wave, state } = useRoom();
    if (!wave) {
        return null;
    }
    const audio = useAudio();
    const language = useLanguage();
    const battlePaused = useSchemaValue(state, 'paused');
    const going = useSchemaValue(wave.schema, 'going');
    const number = useSchemaValue(wave.schema, 'number');
    const timeleft = useSchemaValue(wave.schema, 'timeleft');
    const mobsLeft = useSchemaValue(wave.schema, 'mobsLeft');
    const paused = useSchemaValue(wave.schema, 'paused');
    const timeleftSeconds = Math.ceil(timeleft / 1000);
    const handleClickSkip = () => {
        wave.skipTimeleft();
    };
    useEffect(() => {
        if (!paused && timeleftSeconds <= 3 && timeleftSeconds > 0) {
            audio.play(AudioType.WaveTick);
        }
    }, [paused, timeleftSeconds]);
    return (React.createElement("div", { className: styles.container },
        paused ? (React.createElement("div", { className: cn(styles.frame, styles.paused) },
            React.createElement(IconPause, { className: styles.icon }))) : (React.createElement("div", { className: cn(styles.frame, styles.number, {
                [styles.going]: going,
            }) }, number)),
        React.createElement("div", { className: styles.info }, going ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.value }, mobsLeft.toString().padStart(2, '0')),
            React.createElement("div", { className: styles.label }, language('EnemiesLeft')))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.value }, formatTime(timeleft)),
            React.createElement("div", { className: styles.label }, language('UntilWaveStart'))))),
        !going && !paused && (React.createElement(Interactive, { onClick: handleClickSkip, disabled: battlePaused, className: cn(styles.skip, {
                [styles.active]: !battlePaused,
            }) },
            React.createElement(IconSkip, { className: styles.icon })))));
};
