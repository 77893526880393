import React from 'react';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Score } from '~/client/core/ui/components/amount/score';
import { USER_ONLINE_PLAY_SCORE_RESTRICTION } from '~/shared/core/user/const';
import styles from './styles.module.scss';
import IconLock from './icons/lock.svg';
export const PlayRestriction = () => {
    const language = useLanguage();
    return (React.createElement("div", { className: styles.container },
        React.createElement(IconLock, null),
        React.createElement("div", { className: styles.text },
            React.createElement("div", { className: styles.large },
                React.createElement("span", null, language('Earn')),
                React.createElement(Score, { value: USER_ONLINE_PLAY_SCORE_RESTRICTION, size: 'm' })),
            React.createElement("div", { className: styles.small }, language('UnlockOnlineModes')))));
};
