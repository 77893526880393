import cn from 'classnames';
import React from 'react';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
export const Button = ({ children, onClick, disabled, icon, view = 'default', size = 'small', }) => (React.createElement(Interactive, { disabled: disabled, onClick: onClick, className: cn(styles.button, styles[size], styles[view], {
        [styles.disabled]: disabled,
    }) },
    React.createElement(Icon, { type: icon, className: styles.icon }),
    children));
