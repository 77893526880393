import { useRef, useEffect } from 'react';
import { Device } from '~/client/core/device';
import { useEvent } from '~/client/core/event-steam/ui/hooks/use-event';
export function useRelativeScreen() {
    const refScreen = useRef(null);
    const refTimeout = useRef(null);
    const handleScreenResize = () => {
        const screen = refScreen.current;
        if (!screen) {
            return;
        }
        if (refTimeout.current) {
            clearTimeout(refTimeout.current);
        }
        refTimeout.current = setTimeout(() => {
            refTimeout.current = null;
            screen.style.removeProperty('transform');
            screen.style.removeProperty('transformOrigin');
            screen.style.removeProperty('width');
            screen.style.removeProperty('height');
            const originalWidth = screen.clientWidth;
            const originalHeight = screen.clientHeight;
            const zoom = Device.getScale();
            screen.style.transform = `scale(${zoom})`;
            screen.style.transformOrigin = '0 0';
            screen.style.width = `${originalWidth / zoom}px`;
            screen.style.height = `${originalHeight / zoom}px`;
        }, 50);
    };
    useEvent(Device.events.onScreenResize, handleScreenResize, []);
    useEffect(() => {
        handleScreenResize();
        return () => {
            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
            }
        };
    }, []);
    return refScreen;
}
