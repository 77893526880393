import { Indicator } from './indicator';
import { SceneLayer } from '../../scene/types';
import { MaterialType } from '~/client/core/assets/materials/types';
import { ModelType } from '~/client/core/assets/types';
import { Model } from '~/client/core/render-item/model';
import './resources';
export class Crystal {
    constructor(battle, schema) {
        this.battle = battle;
        this.schema = schema;
        this.renderItem = new Model(battle.scene, {
            model: ModelType.Crystal,
            material: MaterialType.Crystal,
            position: schema.position,
        });
        this.renderItem.eachMeshes((mesh) => {
            mesh.layers.set(SceneLayer.Misc);
        });
        this.renderItem.animator.play('spin', { timeScale: 0.75 });
        this.indicator = new Indicator(this);
        this.listenSchemaVisible();
        this.schema.onRemove(() => {
            this.destroy();
        });
        this.battle.terrain.crystals.add(this);
    }
    destroy() {
        this.indicator.destroy();
        this.renderItem.destroy();
        this.battle.terrain.crystals.delete(this);
    }
    listenSchemaVisible() {
        const selfId = this.battle.getSelfPlayerSchema().id;
        this.schema.visibleFor.onChange(() => {
            const visible = Boolean(this.schema.visibleFor.get(selfId));
            this.renderItem.setVisible(visible);
        });
    }
}
