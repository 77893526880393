import { Assets } from '../../../assets';
import { Logger } from '../../../logger';
export class Animator {
    constructor(model) {
        this.actions = new Map();
        this.paused = false;
        this.timeScale = 1.0;
        this.mixer = model.createAnimationMixer();
        Assets.getModel(model.type).animations.forEach((animation) => {
            const action = this.mixer.clipAction(animation);
            this.activateAction(action, animation.name);
        });
    }
    activateAction(action, name) {
        this.actions.set(name, action);
        this.setWeight(action, 0.0);
        action.play();
    }
    setWeight(action, weight) {
        action.enabled = true;
        action.setEffectiveWeight(weight);
    }
    stopAll() {
        this.actions.forEach((action) => {
            this.setWeight(action, 0.0);
        });
    }
    stop(name) {
        const action = this.actions.get(name);
        if (!action) {
            Logger.warn(`Undefined animation '${name}`);
            return;
        }
        this.setWeight(action, 0.0);
    }
    play(name, { timeScale = 1.0, repeat = true, clamp = false } = {}) {
        const action = this.actions.get(name);
        if (!action) {
            Logger.warn(`Undefined animation '${name}'`);
            return;
        }
        this.setWeight(action, 1.0);
        action.time = 0;
        action.repetitions = repeat ? Infinity : 1;
        action.clampWhenFinished = clamp;
        action.setEffectiveTimeScale(timeScale);
    }
    change(from, to, duration) {
        const actionFrom = this.actions.get(from);
        if (!actionFrom) {
            Logger.warn(`Undefined animation '${from}`);
            return;
        }
        const actionTo = this.actions.get(to);
        if (!actionTo) {
            Logger.warn(`Undefined animation '${to}`);
            return;
        }
        if (actionFrom.getEffectiveWeight() > 0) {
            this.executeCrossFade(actionFrom, actionTo, duration);
        }
    }
    executeCrossFade(actionFrom, actionTo, duration) {
        this.setWeight(actionTo, 1.0);
        actionTo.time = 0;
        actionFrom.crossFadeTo(actionTo, duration, true);
    }
    update(deltaTime) {
        if (!this.paused) {
            this.mixer.update((deltaTime / 1000) * this.timeScale);
        }
    }
}
