import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { OverlayState } from '../../../overlays/state';
import { OverlayType } from '../../../overlays/types';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { useUserData } from '~/client/relay/ui/hooks/use-user-data';
import styles from './styles.module.scss';
export const Kind = ({ label, description, icon, authorizedOnly, accent, onClick, }) => {
    const authorized = useUserData('authorized');
    const [, setOverlay] = useRecoilState(OverlayState);
    const handleClick = useCallback(() => {
        if (!authorizedOnly || authorized) {
            onClick();
        }
        else {
            setOverlay(OverlayType.Auth);
        }
    }, [authorized]);
    return (React.createElement(Interactive, { onClick: handleClick, withAudio: true, className: cn(styles.container, {
            [styles.accent]: accent,
        }) },
        React.createElement(Icon, { type: icon }),
        React.createElement(Section, { direction: 'vertical', gap: 4 },
            React.createElement(Text, { size: '2xl', bold: true, uppercase: true }, label),
            React.createElement(Text, { view: 'secondary', size: 's', uppercase: true }, description))));
};
