import { Vector3 } from 'three';
import { BuildingCategory, BuildingVariant } from './types';
import { BattleMode } from '../../types';
export const BUILDING_SIZE = new Vector3(1.0, 1.0, 1.0);
export const BUILDING_DEFAULT_RADIUS = 4;
export const BUILDINGS_SHARED_CONFIG = {
    [BuildingVariant.Ammunition]: {
        category: BuildingCategory.Resources,
        cost: 100,
        minLevel: {
            [BattleMode.Online]: 2,
            [BattleMode.Single]: 3,
        },
    },
    [BuildingVariant.Wall]: {
        category: BuildingCategory.Defense,
        cost: 30,
        minLevel: {
            [BattleMode.Online]: 3,
            [BattleMode.Single]: 4,
        },
    },
    [BuildingVariant.Generator]: {
        category: BuildingCategory.Resources,
        cost: 60,
        restricted: true,
        limit: 4,
        minLevel: {
            [BattleMode.Online]: 1,
            [BattleMode.Single]: 1,
        },
    },
    [BuildingVariant.Trap]: {
        category: BuildingCategory.Defense,
        cost: 140,
        minLevel: {
            [BattleMode.Online]: 6,
            [BattleMode.Single]: 11,
        },
    },
    [BuildingVariant.SpawnerSmall]: {
        category: BuildingCategory.Spawners,
        cost: 100,
        mode: BattleMode.Online,
        minLevel: {
            [BattleMode.Online]: 1,
            [BattleMode.Single]: Infinity,
        },
    },
    [BuildingVariant.SpawnerMedium]: {
        category: BuildingCategory.Spawners,
        cost: 200,
        mode: BattleMode.Online,
        minLevel: {
            [BattleMode.Online]: 5,
            [BattleMode.Single]: Infinity,
        },
    },
    [BuildingVariant.SpawnerLarge]: {
        category: BuildingCategory.Spawners,
        cost: 300,
        mode: BattleMode.Online,
        minLevel: {
            [BattleMode.Online]: 9,
            [BattleMode.Single]: Infinity,
        },
    },
    [BuildingVariant.Radar]: {
        category: BuildingCategory.Defense,
        cost: 120,
        minLevel: {
            [BattleMode.Online]: 8,
            [BattleMode.Single]: 13,
        },
    },
    [BuildingVariant.TowerFire]: {
        category: BuildingCategory.Towers,
        cost: 80,
        minLevel: {
            [BattleMode.Online]: 1,
            [BattleMode.Single]: 1,
        },
    },
    [BuildingVariant.TowerFrost]: {
        category: BuildingCategory.Towers,
        cost: 100,
        minLevel: {
            [BattleMode.Online]: 4,
            [BattleMode.Single]: 4,
        },
    },
    [BuildingVariant.TowerLaser]: {
        category: BuildingCategory.Towers,
        cost: 180,
        minLevel: {
            [BattleMode.Online]: 6,
            [BattleMode.Single]: 9,
        },
    },
    [BuildingVariant.TowerElectro]: {
        category: BuildingCategory.Towers,
        cost: 220,
        minLevel: {
            [BattleMode.Online]: 10,
            [BattleMode.Single]: 15,
        },
    },
};
