import { Droid } from '../..';
import { ShotFactory } from '~/client/battle/entity/shot/factory';
import { ModelType } from '~/client/core/assets/types';
import './resources';
export class DroidCombat extends Droid {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.DroidCombat,
        }, schema);
        this.shot = ShotFactory.create(this.battle, schema.weapon.shot);
    }
    destroy() {
        this.shot.destroy();
        super.destroy();
    }
    rotateToVelocity() {
        const targetId = this.schema.weapon.targetId;
        if (targetId) {
            const target = this.battle.getEntity(targetId);
            if (target) {
                this.rotateToPosition(target.renderItem.position);
            }
        }
        else {
            super.rotateToVelocity();
        }
    }
}
