import React from 'react';
import { useBuilding } from '../../hooks/use-building';
import { Boost } from '~/client/battle/entity/ui/components/boost';
import { Indicator } from '~/client/battle/entity/ui/components/indicator';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { BuildingMessage } from '~/shared/battle/entity/building/types';
import { EntityMessage } from '~/shared/battle/entity/types';
import IconHeal from './icons/heal.svg';
export const Indicators = () => {
    const building = useBuilding();
    const audio = useAudio();
    const language = useLanguage();
    const onUpgrade = () => {
        if (building.selfOwn) {
            audio.play(AudioType.BuildingUpgrade);
        }
    };
    const onHeal = () => {
        if (building.selfOwn) {
            audio.play(AudioType.BuildingRepair);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Indicator, { entity: building, messageShow: BuildingMessage.Boost, messageHide: BuildingMessage.Unboost },
            React.createElement(Boost, { opponent: !building.selfOwn })),
        React.createElement(Indicator, { entity: building, messageShow: BuildingMessage.Upgrade, onDisplay: onUpgrade }, language('Upgraded')),
        React.createElement(Indicator, { entity: building, messageShow: EntityMessage.Heal, onDisplay: onHeal },
            React.createElement(IconHeal, null))));
};
