import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';
import binCrystal from './crystal.bin';
import gltfCrystal from './crystal.gltf';
Assets.addModel(ModelType.Crystal, {
    gltf: gltfCrystal,
    bin: {
        origin: 'crystal.bin',
        path: binCrystal,
    },
});
