import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';
import audioRoar from './roar.mp3';
Assets.addAudio(AudioType.MobBossRoar, {
    path: audioRoar,
    mode: AudioMode.Track3D,
    volume: 0.7,
    poolSize: 1,
    single: true,
});
