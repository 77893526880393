import { TerrainBiomeType } from '../types';
export const TILE_FACES = [
    {
        // Top
        r: { x: -0.5 * Math.PI, y: 0, z: 0 },
        p: { x: 0, y: 0.5, z: 0 },
    },
    {
        // Left near
        r: { x: 0, y: 0, z: 0 },
        p: { x: 0, y: 0.0, z: 0.5 },
    },
    {
        // Right near
        r: { x: 0, y: 0.5 * Math.PI, z: 0 },
        p: { x: 0.5, y: 0.0, z: 0 },
    },
];
export const TILE_OFFSET = {
    [TerrainBiomeType.Liquid]: 0.2,
    [TerrainBiomeType.Coast]: 0.4,
    [TerrainBiomeType.Fields]: 0.5,
    [TerrainBiomeType.Mounts]: 0.5,
};
