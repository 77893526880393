import { BuildingSpawner } from '..';
import { ModelType } from '~/client/core/assets/types';
import './resources';
export class BuildingSpawnerSmall extends BuildingSpawner {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.BuildingSpawnerSmall,
        }, schema);
    }
}
