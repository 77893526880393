import { INVENTORY_ITEMS } from '../const';
import { InventoryItemType } from '../item/types';
import { DROIDS_SHARED_CONFIG } from '~/shared/battle/entity/unit/npc/droid/const';
import { SKILLS_SHARED_CONFIG } from '~/shared/battle/entity/unit/player/skill/const';
export class InventoryUtils {
    static getItems(inventory, targetType) {
        const result = new Map();
        inventory.slots.forEach((item, key) => {
            const [type, slot] = key.split(':');
            if (type === targetType) {
                result.set(Number(slot), item);
            }
        });
        return result;
    }
    static getItem(inventory, targetType, slot = 0) {
        var _a;
        return (_a = this.getItems(inventory, targetType).get(slot)) !== null && _a !== void 0 ? _a : null;
    }
    static getItemSlot(inventory, targetItem) {
        const data = Array.from(inventory.slots.entries()).find(([, item]) => item === targetItem);
        if (!data) {
            return null;
        }
        const [, slot] = data[0].split(':');
        return Number(slot);
    }
    static getItemType(name) {
        const type = INVENTORY_ITEMS[name];
        if (!type) {
            throw Error('Unknown item name');
        }
        return type;
    }
    static getItemCost(item) {
        switch (this.getItemType(item)) {
            case InventoryItemType.Droid: {
                const droid = item;
                return DROIDS_SHARED_CONFIG[droid].cost;
            }
            case InventoryItemType.Skill: {
                const skill = item;
                return SKILLS_SHARED_CONFIG[skill].cost;
            }
            default: {
                return 0;
            }
        }
    }
}
