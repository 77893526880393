import { OrthographicCamera, Vector3, WebGLRenderer } from 'three';
import { MINIMAP_FRUSTUM, MINIMAP_REPAINT_BIOMES, MINIMAP_UPDATE_RATE } from './const';
import { SceneLayer } from '../scene/types';
import { TILE_COLORS } from '../terrain/tile/const';
import { Assets } from '~/client/core/assets';
import { TERRAIN_SIZE } from '~/shared/battle/terrain/const';
import { EventStream } from '~/shared/core/event-stream';
export class Minimap {
    constructor(battle) {
        this.renderer = null;
        this.updateTick = 0;
        this.events = {
            onUpdate: new EventStream(),
        };
        this.biomeMaterials = [];
        this.battle = battle;
        this.camera = new OrthographicCamera(-MINIMAP_FRUSTUM, MINIMAP_FRUSTUM, MINIMAP_FRUSTUM, -MINIMAP_FRUSTUM, 1, 1000);
        this.camera.layers.enable(SceneLayer.Ground);
        this.camera.layers.enable(SceneLayer.Marker);
        this.camera.layers.enable(SceneLayer.Fog);
        this.camera.position.set(TERRAIN_SIZE.x / 2, 50, TERRAIN_SIZE.z / 2);
        this.camera.lookAt(new Vector3(TERRAIN_SIZE.x / 2, 0, TERRAIN_SIZE.z / 2));
        this.createBiomeMaterials();
        this.onSceneRender = this.onSceneRender.bind(this);
    }
    enable(canvas) {
        this.renderer = new WebGLRenderer({
            canvas,
            antialias: false,
        });
        this.renderer.setSize(200, 200);
        this.render();
        this.battle.scene.events.onRender.on(this.onSceneRender);
    }
    disable() {
        if (this.renderer) {
            this.renderer.dispose();
            this.renderer = null;
        }
        this.battle.scene.events.onRender.off(this.onSceneRender);
    }
    onSceneRender() {
        this.update();
    }
    update() {
        this.updateTick += this.battle.scene.deltaTime;
        if (this.updateTick >= MINIMAP_UPDATE_RATE) {
            this.events.onUpdate.invoke();
            this.render();
            this.updateTick %= MINIMAP_UPDATE_RATE;
        }
    }
    render() {
        var _a;
        this.biomeMaterials.forEach(({ material, color }) => {
            material.color.setHex(color);
        });
        (_a = this.renderer) === null || _a === void 0 ? void 0 : _a.render(this.battle.scene, this.camera);
        this.biomeMaterials.forEach(({ material, originColor }) => {
            material.color.setHex(originColor);
        });
    }
    createBiomeMaterials() {
        MINIMAP_REPAINT_BIOMES.forEach((biome) => {
            TILE_COLORS[biome].forEach((color, i) => {
                this.biomeMaterials.push({
                    material: Assets.getMaterial(`${biome}${i}`),
                    color: TILE_COLORS[biome][0],
                    originColor: color,
                });
            });
        });
    }
}
