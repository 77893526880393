import cn from 'classnames';
import React, { useCallback } from 'react';
import { ScreenType } from '../../../../types';
import { useScreen } from '~/client/battle/ui/hooks/use-screen';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
import IconMenu from './icons/menu.svg';
export const MenuButton = () => {
    const { screen, openScreen, closeScreen } = useScreen();
    const handleClick = useCallback(() => {
        if (screen === ScreenType.Menu) {
            closeScreen();
        }
        else {
            openScreen(ScreenType.Menu);
        }
    }, [screen]);
    return (React.createElement(Interactive, { onClick: handleClick, className: cn(styles.button, {
            [styles.active]: screen === ScreenType.Menu,
        }) },
        React.createElement(IconMenu, { className: styles.icon })));
};
