import React, { useEffect, useState } from 'react';
import { Confirmation } from './confirmation';
import { MENU_TOGGLE_KEY } from './const';
import { MenuPageType } from './types';
import { ScreenType } from '../../../../types';
import { Button } from '../../../button';
import { useScreen } from '~/client/battle/ui/hooks/use-screen';
import { Client } from '~/client/core/client';
import { useEvent } from '~/client/core/event-steam/ui/hooks/use-event';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Section } from '~/client/core/ui/components/section';
import { SettingsPanel } from '~/client/core/ui/components/settings-panel';
import { BattleMode } from '~/shared/battle/types';
import { RoomType } from '~/shared/core/room/types';
import styles from './styles.module.scss';
export const MenuScreen = () => {
    const battle = useRoom();
    const language = useLanguage();
    const { screen, openScreen, closeScreen } = useScreen();
    const [page, setPage] = useState(MenuPageType.Main);
    const visible = screen === ScreenType.Menu;
    const handleClickBack = () => {
        setPage(MenuPageType.Main);
    };
    const handleClickLeave = () => {
        Client.joinRoomByType(RoomType.Relay);
    };
    const handleClickRestart = () => {
        Client.setLoading();
        battle.restart(false);
        closeScreen();
    };
    useEvent(InputKeyboard.events.onKeyDown, (event) => {
        if (event.code !== MENU_TOGGLE_KEY) {
            return;
        }
        event.preventDefault();
        if (screen) {
            closeScreen();
        }
        else {
            openScreen(ScreenType.Menu);
            setPage(MenuPageType.Main);
        }
    }, [screen]);
    useEffect(() => {
        battle.scene.audio.muffle(visible);
    }, [visible]);
    return visible && (() => {
        switch (page) {
            case MenuPageType.Main:
                return (React.createElement(Section, { direction: "vertical", gap: 8 },
                    React.createElement(Button, { onClick: closeScreen, view: "accent" }, language('Continue')),
                    battle.state.mode === BattleMode.Single && (React.createElement(Button, { onClick: () => setPage(MenuPageType.Restart) }, language('Restart'))),
                    React.createElement(Button, { onClick: () => setPage(MenuPageType.Settings) }, language('Settings')),
                    React.createElement("br", null),
                    React.createElement(Button, { onClick: () => setPage(MenuPageType.Leave), view: "negative" }, language('MainMenu'))));
            case MenuPageType.Settings:
                return (React.createElement("div", { className: styles.settings },
                    React.createElement(Section, { direction: "vertical", align: "center", gap: 48 },
                        React.createElement(SettingsPanel, null),
                        React.createElement(Button, { onClick: handleClickBack }, language('Back')))));
            case MenuPageType.Restart:
                return (React.createElement(Confirmation, { text: language('RestartConfirm'), onConfirm: handleClickRestart, onDecline: handleClickBack }));
            case MenuPageType.Leave:
                return (React.createElement(Confirmation, { text: language('LeaveConfirm'), onConfirm: handleClickLeave, onDecline: handleClickBack }));
        }
    })();
};
