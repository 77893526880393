import React from 'react';
import { KeyboardKey } from '~/client/core/input/keyboard/ui/components/key';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';
import styles from './styles.module.scss';
export const Key = ({ children, action, target }) => {
    const language = useLanguage();
    return (React.createElement(Section, { direction: 'vertical', gap: 6 },
        React.createElement(Section, { direction: 'horizontal', align: 'center', gap: 12 },
            React.createElement("div", { className: styles.text }, language(action)),
            React.createElement(KeyboardKey, { size: 'l' }, target),
            React.createElement("div", { className: styles.text }, language('To'))),
        React.createElement("div", { className: styles.text }, children)));
};
