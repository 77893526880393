import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';
import bin from './eye-child.bin';
import gltf from './eye-child.gltf';
Assets.addModel(ModelType.MobEyeChild, {
    gltf,
    bin: {
        origin: 'eye-child.bin',
        path: bin,
    },
});
