import cn from 'classnames';
import React, { useCallback } from 'react';
import { SCREEN_BUTTON_HOTKEYS, SCREEN_BUTTON_ICON } from './const';
import { useScreen } from '~/client/battle/ui/hooks/use-screen';
import { Device } from '~/client/core/device';
import { KeyboardKey } from '~/client/core/input/keyboard/ui/components/key';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { DeviceType } from '~/shared/core/device/types';
import styles from './styles.module.scss';
export const ScreenButton = ({ type, children }) => {
    const language = useLanguage();
    const { screen, openScreen, closeScreen } = useScreen();
    const hotkey = SCREEN_BUTTON_HOTKEYS[type];
    const active = screen === type;
    const handleClick = useCallback(() => {
        if (active) {
            closeScreen();
        }
        else {
            openScreen(type);
        }
    }, [active]);
    return (React.createElement(Interactive, { onClick: handleClick, className: cn(styles.button, {
            [styles.active]: active,
        }) },
        React.createElement(Icon, { type: SCREEN_BUTTON_ICON[type], className: styles.icon }),
        Device.type === DeviceType.Desktop && hotkey && !active && (React.createElement("div", { className: styles.hotkey },
            language('OrHold'),
            React.createElement(KeyboardKey, { size: 'm' }, (Device.isMacOS && hotkey === 'Alt') ? 'Option' : hotkey))),
        children && (React.createElement("div", { className: styles.content }, children))));
};
