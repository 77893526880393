import { useSchemaListener } from 'colyseus-schema-hooks';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TutorialContent } from './content';
import { CurrentTutorialStepState, ActiveTutorialStepsState } from '../../state';
import { useScreen } from '~/client/battle/ui/hooks/use-screen';
import { Device } from '~/client/core/device';
import { useEventValue } from '~/client/core/event-steam/ui/hooks/use-event-value';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { useBatch } from '~/client/core/ui/hooks/use-batch';
import { TutorialStep, TutorialStepState } from '~/shared/battle/tutorial/types';
import { DeviceType } from '~/shared/core/device/types';
import styles from './styles.module.scss';
export const TutorialHints = () => {
    var _a;
    const { builder, state } = useRoom();
    const steps = (_a = state.tutorial) === null || _a === void 0 ? void 0 : _a.steps;
    if (!steps) {
        return;
    }
    const { screen } = useScreen();
    const [, setActiveSteps] = useRecoilState(ActiveTutorialStepsState);
    const currentStep = useRecoilValue(CurrentTutorialStepState);
    const build = useEventValue(builder.events.onToggleBuild, false);
    const active = currentStep && !build && !screen && (
    // TODO: Make abstracted
    currentStep !== TutorialStep.Attack ||
        Device.type === DeviceType.Desktop);
    const update = useBatch(() => {
        const activeSteps = new Set();
        steps.forEach((state, step) => {
            if (state === TutorialStepState.Active) {
                activeSteps.add(step);
            }
        });
        setActiveSteps(activeSteps);
    });
    useSchemaListener(steps, {
        onAdd: update,
        onChange: update,
        onRemove: update,
    }, [steps]);
    return active && (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.container },
            React.createElement(TutorialContent, { step: currentStep }))));
};
