export class Timeouts {
    constructor(time) {
        this.callbacks = new Map();
        this.time = time !== null && time !== void 0 ? time : {
            get: () => performance.now(),
        };
    }
    add(callback, duration) {
        const targetTime = this.time.get() + duration;
        this.callbacks.set(callback, targetTime);
    }
    remove(callback) {
        this.callbacks.delete(callback);
    }
    clear() {
        this.callbacks.clear();
    }
    update() {
        this.callbacks.forEach((targetTime, callback) => {
            if (this.time.get() >= targetTime) {
                callback();
                this.callbacks.delete(callback);
            }
        });
    }
}
