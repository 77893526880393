import { Mob } from '../..';
import { ModelType } from '~/client/core/assets/types';
import { AudioType } from '~/client/core/audio/types';
import './resources';
export class MobBoss extends Mob {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.MobBoss,
        }, schema);
    }
    onDead() {
        super.onDead();
        this.playAudio(AudioType.MobBossRoar);
    }
}
