import { Shot } from '../..';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { Circle } from '~/client/core/render-item/shape/circle';
import './resources';
export class Electro extends Shot {
    constructor(battle, schema) {
        const renderItem = new Circle(battle.scene, {
            material: MaterialType.Electro,
            position: schema.position,
            radius: schema.radius,
            alpha: 0.05,
        });
        super(battle, {
            renderItem,
            audioEffect: AudioType.ShotElectro,
        }, schema);
        this.listenSchemaRadius();
    }
    listenSchemaRadius() {
        this.schema.listen('radius', (radius) => {
            this.renderItem.setRadius(radius);
        });
    }
}
