import React from 'react';
import { useRecoilValue } from 'recoil';
import { useBuilding } from '../../hooks/use-building';
import { CurrentTutorialStepState } from '~/client/battle/tutorial/ui/state';
import styles from './styles.module.scss';
import IconArrow from './icons/arrow.svg';
export const UpgradeHint = () => {
    const building = useBuilding();
    const currentTutorialStep = useRecoilValue(CurrentTutorialStepState);
    const visible = (building.selfOwn &&
        currentTutorialStep === `Upgrade${building.schema.variant}`);
    return visible && (React.createElement(IconArrow, { className: styles.icon }));
};
