import { atom, selector } from 'recoil';
export const ActiveTutorialStepsState = atom({
    key: 'ActiveTutorialSteps',
    default: new Set(),
});
export const CurrentTutorialStepState = selector({
    key: 'CurrentTutorialStep',
    get: ({ get }) => {
        var _a;
        const steps = get(ActiveTutorialStepsState);
        return (_a = steps.values().next().value) !== null && _a !== void 0 ? _a : null;
    },
});
