import React, { useRef, useState } from 'react';
import { useMob } from '../../../hooks/use-mob';
import { useEvent } from '~/client/core/event-steam/ui/hooks/use-event';
import { Icon } from '~/client/core/ui/components/icon';
export const StateIcon = () => {
    const mob = useMob();
    const [visible, setVisible] = useState(Boolean(mob.stateIcon));
    const refIcon = useRef(mob.stateIcon);
    useEvent(mob.events.onChangeStateIcon, (icon) => {
        refIcon.current = icon;
        setVisible(Boolean(icon));
    }, []);
    return visible && (React.createElement(Icon, { type: refIcon.current }));
};
