export var SkillVariant;
(function (SkillVariant) {
    SkillVariant["BoostSpeed"] = "BoostSpeed";
    SkillVariant["InspirationDroid"] = "InspirationDroid";
    SkillVariant["InspirationMobs"] = "InspirationMobs";
    SkillVariant["InspirationTowers"] = "InspirationTowers";
    SkillVariant["Vampire"] = "Vampire";
    SkillVariant["DamageReflection"] = "DamageReflection";
    SkillVariant["Sacrifice"] = "Sacrifice";
    SkillVariant["DiscountWalls"] = "DiscountWalls";
    SkillVariant["BoostGenerators"] = "BoostGenerators";
    SkillVariant["CallingMobs"] = "CallingMobs";
    SkillVariant["AttackRecovery"] = "AttackRecovery";
    SkillVariant["HighDamage"] = "HighDamage";
    SkillVariant["FrostWave"] = "FrostWave";
})(SkillVariant || (SkillVariant = {}));
export var SkillType;
(function (SkillType) {
    SkillType["Active"] = "Active";
    SkillType["Passive"] = "Passive";
})(SkillType || (SkillType = {}));
