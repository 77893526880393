import { Builder } from './builder';
import { BATTLE_PING_LISTEN_RATE } from './const';
import { BuildingFactory } from './entity/building/factory';
import { DroidFactory } from './entity/unit/npc/droid/factory';
import { MobFactory } from './entity/unit/npc/mob/factory';
import { Player } from './entity/unit/player';
import { Minimap } from './minimap';
import { BattleScene } from './scene';
import { Terrain } from './terrain';
import { BattleScreen } from './ui';
import { Wave } from './wave';
import { AudioType } from '~/client/core/audio/types';
import { Client } from '~/client/core/client';
import { Device } from '~/client/core/device';
import { Messages } from '~/client/core/messages';
import { MessagesBuffer } from '~/client/core/messages/buffer';
import { Room } from '~/client/core/room';
import { SDK } from '~/client/core/sdk';
import { Interface } from '~/client/core/ui';
import { BattleMessage, BattleMode, BattleStage } from '~/shared/battle/types';
import { EventStream } from '~/shared/core/event-stream';
import { Timeouts } from '~/shared/core/time/timeouts';
import './resources';
export class Battle extends Room {
    constructor(room) {
        super(room);
        this.timeouts = new Timeouts();
        this.wave = null;
        this.ping = 0;
        this.entitiesUI = new Map();
        this.entities = new Map();
        this.events = {
            onPreparing: new EventStream(),
            onStart: new EventStream(),
            onFinish: new EventStream(),
            onEntityChangeUI: new EventStream(),
        };
        this.messagesBuffer = new MessagesBuffer();
        this.messages = new Messages(this.origin, this.messagesBuffer);
        this.scene = new BattleScene(this);
        this.builder = new Builder(this);
        this.terrain = new Terrain(this);
        this.minimap = new Minimap(this);
        this.bindSchemaWithEntities();
        this.listenSchemaPing();
        this.listenSchemaStage();
        this.listenSchemaWave();
        Interface.mount(BattleScreen, this);
        this.origin.onLeave(() => {
            SDK.togglePlaying(false);
        });
        Client.unmarkNewbie();
        this.onScreenRelease = this.onScreenRelease.bind(this);
        Device.events.onScreenRelease.on(this.onScreenRelease);
        this.onSceneUpdate = this.onSceneUpdate.bind(this);
        this.scene.events.onUpdate.on(this.onSceneUpdate);
    }
    destroy() {
        clearInterval(this.pingListener);
        this.removeEntities();
        this.timeouts.clear();
        this.messagesBuffer.clear();
        this.scene.destroy();
        this.terrain.destroy();
        this.builder.destroy();
        Device.events.onScreenRelease.off(this.onScreenRelease);
        this.scene.events.onUpdate.off(this.onSceneUpdate);
        super.destroy();
    }
    bindSchemaWithEntities() {
        this.state.players.onAdd((player) => {
            if (!player.bot) {
                new Player(this, player);
            }
        });
        this.state.droids.onAdd((droid) => {
            DroidFactory.create(this, droid);
        });
        this.state.mobs.onAdd((mob) => {
            MobFactory.create(this, mob);
        });
        this.state.buildings.onAdd((building) => {
            BuildingFactory.create(this, building);
        });
        const offChange = this.state.onChange(() => {
            offChange();
            if (this.state.paused) {
                setTimeout(() => {
                    this.scene.camera.update();
                    this.scene.render();
                });
            }
        });
    }
    removeEntities() {
        this.entities.forEach((entity) => {
            entity.destroy();
        });
        this.entities.clear();
    }
    getEntity(id) {
        var _a;
        return ((_a = this.entities.get(id)) !== null && _a !== void 0 ? _a : null);
    }
    getSelfPlayer() {
        const schema = this.getSelfPlayerSchema();
        const player = this.getEntity(schema.id);
        if (!player) {
            throw Error('Unknown self player');
        }
        return player;
    }
    getSelfPlayerSchema() {
        const player = this.state.players.get(this.sessionId);
        if (!player) {
            throw Error('Unknown self player schema');
        }
        return player;
    }
    getOpponentPlayerSchema() {
        const player = Array.from(this.state.players.values()).find((player) => (player.id !== this.sessionId));
        if (!player) {
            throw Error('Unknown opponent player');
        }
        return player;
    }
    listenSchemaPing() {
        this.pingListener = setInterval(() => {
            this.messages.send(BattleMessage.Ping, {
                stamp: performance.now(),
            });
        }, BATTLE_PING_LISTEN_RATE);
        this.messages.on(BattleMessage.Ping, ({ stamp }) => {
            const now = performance.now();
            this.ping = now - stamp;
        });
    }
    listenSchemaStage() {
        this.state.listen('stage', (stage) => {
            switch (stage) {
                case BattleStage.Preparing: {
                    this.preparing();
                    break;
                }
                case BattleStage.Started: {
                    this.start();
                    Client.hideLoading();
                    break;
                }
                case BattleStage.Finished: {
                    this.finish();
                    Client.hideLoading();
                    break;
                }
            }
        });
    }
    listenSchemaWave() {
        this.state.listen('wave', (wave) => {
            if (wave) {
                this.wave = new Wave(this);
            }
        });
    }
    restart(loadSave) {
        this.messages.send(BattleMessage.Restart, {
            loadSave,
        });
    }
    preparing() {
        this.events.onPreparing.invoke();
    }
    start() {
        SDK.togglePlaying(true);
        this.scene.toggleControls(true);
        this.events.onStart.invoke();
    }
    togglePause(paused) {
        if (this.state.mode === BattleMode.Online ||
            this.state.paused === paused) {
            return;
        }
        SDK.togglePlaying(!paused);
        this.messages.send(BattleMessage.TogglePause, { paused });
    }
    finish() {
        SDK.togglePlaying(false);
        this.scene.toggleControls(false);
        const audio = this.state.winnerId === this.sessionId
            ? AudioType.Win
            : AudioType.GameOver;
        this.scene.audio.play2D(audio);
        this.events.onFinish.invoke();
    }
    setEntityUI(entity, ui) {
        if (ui) {
            this.entitiesUI.set(entity, ui);
        }
        else {
            this.entitiesUI.delete(entity);
        }
        this.events.onEntityChangeUI.invoke();
    }
    onSceneUpdate() {
        this.timeouts.update();
    }
    onScreenRelease() {
        // To apply actual schema
        setTimeout(() => {
            this.entities.forEach((entity) => {
                entity.synchronize();
            });
        });
    }
}
Battle.allowReconnection = true;
