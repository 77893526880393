import { useRecoilState } from 'recoil';
import { InventorySelectionState } from '../state';
export function useInventorySelection() {
    const [inventorySelection, setInventorySelection] = useRecoilState(InventorySelectionState);
    if (!inventorySelection) {
        throw Error('Inventory selection isn`t active');
    }
    const closeSelection = () => {
        setInventorySelection(null);
    };
    return Object.assign(Object.assign({}, inventorySelection), { closeSelection });
}
