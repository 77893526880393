import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { useSelfPlayerSchema } from '../../hooks/use-self-player-schema';
import { Icon } from '~/client/core/ui/components/icon';
import styles from './styles.module.scss';
import IconResources from './icons/resources.svg';
export const Cost = ({ amount, check = true }) => {
    const player = useSelfPlayerSchema();
    const resources = useSchemaValue(player, 'resources');
    const enough = !check || resources >= amount;
    return (React.createElement("div", { className: cn(styles.wrapper, {
            [styles.less]: !enough,
        }) },
        React.createElement(Icon, { type: IconResources, className: styles.icon }),
        React.createElement("div", { className: styles.amount }, amount)));
};
