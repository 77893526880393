var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Shot } from '../..';
import { Sphere } from '~/client/core/render-item/shape/sphere';
export class Bullet extends Shot {
    constructor(battle, _a, schema) {
        var { size = 1.0, audioEffect } = _a, config = __rest(_a, ["size", "audioEffect"]);
        const renderItem = new Sphere(battle.scene, Object.assign(Object.assign({}, config), { radius: size * 0.1, position: schema.position }));
        super(battle, {
            renderItem,
            audioEffect,
        }, schema);
    }
}
