import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useCallback, useEffect } from 'react';
import { SkillInfo } from './info';
import { SKILL_ICON } from '../../skill-icon/const';
import { useSelfPlayer } from '~/client/battle/ui/hooks/use-self-player';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { Device } from '~/client/core/device';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { SkillType } from '~/shared/battle/entity/unit/player/skill/types';
import { DeviceType } from '~/shared/core/device/types';
import styles from './styles.module.scss';
export const SkillSlot = ({ variant, index, skill }) => {
    const battle = useRoom();
    const audio = useAudio();
    const player = useSelfPlayer();
    const recoveryLeft = skill ? useSchemaValue(skill, 'recoveryLeft') : 0;
    const angle = (90 / (player.schema.skills.size - 1)) * index - 45;
    const usable = recoveryLeft === 0 && skill.type === SkillType.Active;
    const handleClick = useCallback(() => {
        audio.play(AudioType.UseSkill);
        player.useSkill(variant);
    }, []);
    const handleKeyDown = (event) => {
        if (battle.state.paused) {
            return;
        }
        const key = Number(event.key);
        if (key === index + 1) {
            handleClick();
        }
    };
    useEffect(() => {
        if (Device.type === DeviceType.Mobile || !usable) {
            return;
        }
        const eventKeyDown = InputKeyboard.events.onKeyDown.on(handleKeyDown);
        return () => {
            eventKeyDown.off();
        };
    }, [usable]);
    return (React.createElement(Interactive, { onClick: handleClick, disabled: !usable, className: cn(styles.container, styles[skill.type.toLowerCase()], {
            [styles.usable]: usable,
        }), style: Device.type === DeviceType.Mobile ? {
            transform: `rotate(${angle}deg) translate(-130px,-130px) rotate(${-angle}deg)`,
        } : undefined },
        Device.type === DeviceType.Desktop && (React.createElement(SkillInfo, { variant: variant, skill: skill })),
        recoveryLeft > 0 && (React.createElement("div", { className: styles.timeout, style: {
                '--progress': `${(1 - (recoveryLeft / skill.recovery)) * 360}deg`,
            } })),
        React.createElement(Icon, { type: SKILL_ICON[variant], className: styles.icon }),
        Device.type === DeviceType.Desktop && (React.createElement("div", { className: styles.hotkey }, usable ? index + 1 : '·'))));
};
