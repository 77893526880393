import cn from 'classnames';
import React from 'react';
import { Score } from '~/client/core/ui/components/amount/score';
import { Waves } from '~/client/core/ui/components/amount/waves';
import { Text } from '~/client/core/ui/components/text';
import { useUserData } from '~/client/relay/ui/hooks/use-user-data';
import styles from './styles.module.scss';
export const Record = ({ place, name, score, waves }) => {
    const currentName = useUserData('name');
    const self = name === currentName;
    return (React.createElement("tr", { className: styles.row },
        React.createElement("td", { width: '100%' },
            React.createElement("div", { className: styles.info },
                React.createElement("div", { className: cn(styles.place, {
                        [styles.self]: self,
                    }) }, place),
                React.createElement(Text, { size: 'm' }, name))),
        React.createElement("td", null,
            React.createElement(Score, { value: score })),
        React.createElement("td", null,
            React.createElement(Waves, { value: waves }))));
};
