export var DroidVariant;
(function (DroidVariant) {
    DroidVariant["Combat"] = "Combat";
    DroidVariant["Mechanic"] = "Mechanic";
    DroidVariant["Medic"] = "Medic";
})(DroidVariant || (DroidVariant = {}));
/**
 * Messages
 */
export var DroidMessage;
(function (DroidMessage) {
    DroidMessage["Boost"] = "Droid:Boost";
    DroidMessage["Unboost"] = "Droid:Unboost";
})(DroidMessage || (DroidMessage = {}));
