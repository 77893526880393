import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';
import audioExtraAttack from './extra-attack.mp3';
import audioFrost1 from './frost1.mp3';
import audioFrost2 from './frost2.mp3';
import audioGore from './gore.mp3';
Assets.addAudio(AudioType.MobFrost, {
    path: [audioFrost1, audioFrost2],
    mode: AudioMode.Track3D,
    volume: 0.25,
    poolSize: 6,
    single: true,
});
Assets.addAudio(AudioType.MobGore, {
    path: audioGore,
    mode: AudioMode.Track3D,
    volume: 0.2,
    poolSize: 4,
    single: true,
});
Assets.addAudio(AudioType.MobExtraAttack, {
    path: audioExtraAttack,
    mode: AudioMode.Track3D,
    volume: 0.4,
    poolSize: 1,
    single: true,
});
