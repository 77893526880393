import { Vector3 } from 'three';
export class UnitUtils {
    static getLookUpPosition(position, velocity, size) {
        const radius = size.z / 2;
        return new Vector3()
            .copy(velocity)
            .normalize()
            .multiplyScalar(radius)
            .add(position);
    }
}
