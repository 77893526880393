import React, { useCallback } from 'react';
import { useUserData } from '../../../hooks/use-user-data';
import { Client } from '~/client/core/client';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Score } from '~/client/core/ui/components/amount/score';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { UserAuthRequest } from '~/shared/core/user/auth/types';
import styles from './styles.module.scss';
import IconLogout from './icons/logout.svg';
export const UserInfo = () => {
    const room = useRoom();
    const name = useUserData('name');
    const crystals = useUserData('crystals');
    const score = useUserData('score');
    const handleClickLogout = useCallback(() => {
        room.sendRequest(UserAuthRequest.Logout).then(() => {
            Client.removeAuthToken();
            room.remountInterface();
        });
    }, []);
    return (React.createElement("div", { className: styles.container },
        React.createElement(Section, { direction: "vertical", align: "left", gap: 8 },
            React.createElement(Text, { size: "m", bold: true, nowrap: true }, name),
            React.createElement(Section, { direction: "horizontal", gap: 16 },
                React.createElement(Crystals, { value: crystals }),
                React.createElement(Score, { value: score }))),
        React.createElement(Interactive, { onClick: handleClickLogout, className: styles.logout },
            React.createElement(IconLogout, null))));
};
