import { MaterialType } from '~/client/core/assets/materials/types';
import { Plane } from '~/client/core/render-item/shape/plane';
import './resources';
export class Indicator extends Plane {
    constructor(unit) {
        const size = unit.schema.size.z;
        super(unit.battle.scene, {
            position: { x: 0.0, y: 0.01, z: 0.0 },
            scale: { x: size, y: size, z: size },
            material: unit.selfOwn
                ? MaterialType.IndicatorSelf
                : MaterialType.IndicatorOpponent,
        });
        unit.renderItem.object.add(this.object);
    }
}
