var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Logger } from '../../logger';
export class MessagesBuffer {
    constructor() {
        this.messages = new Map();
    }
    clear(channel) {
        if (channel) {
            this.messages.forEach((callbacks) => {
                callbacks.delete(channel);
            });
        }
        else {
            this.messages.clear();
        }
    }
    onMessage(origin, channel, message, callback) {
        let callbacks = this.messages.get(message);
        if (callbacks) {
            callbacks.set(channel, callback);
            return;
        }
        else {
            callbacks = new Map([[channel, callback]]);
            this.messages.set(message, callbacks);
        }
        origin.onMessage(message, (_a) => {
            var { _channel } = _a, payload = __rest(_a, ["_channel"]);
            if (!_channel) {
                Logger.error(`Undefined channel from server message '${message}'`);
                return;
            }
            const callback = callbacks.get(_channel);
            if (!callback) {
                // if (__MODE === 'development') {
                //   Logger.warn(`Unknown channel '${_channel}' from server message '${message}'`);
                // }
                return;
            }
            callback(payload);
        });
    }
}
