import React from 'react';
import { PARAMETER_ICON } from './const';
import { Icon } from '~/client/core/ui/components/icon';
import { Section } from '~/client/core/ui/components/section';
import styles from './styles.module.scss';
export const Parameter = ({ type, value, alert }) => (React.createElement("div", { className: styles.wrapper },
    React.createElement(Section, { direction: "horizontal", align: "center", gap: 8 },
        React.createElement(Icon, { type: PARAMETER_ICON[type], className: styles.icon }),
        React.createElement("div", { className: styles.amount }, value)),
    alert && value === 0 && (React.createElement("div", { className: styles.alert }, alert))));
