export var PlayerCheat;
(function (PlayerCheat) {
    PlayerCheat["GiveResources"] = "RICH";
    PlayerCheat["GiveExperience"] = "GOUP";
    PlayerCheat["Godmode"] = "HOLY";
    PlayerCheat["BoostSpeed"] = "VELO";
    PlayerCheat["NextWave"] = "NEXT";
})(PlayerCheat || (PlayerCheat = {}));
/**
 * Messages
 */
export var PlayerMessage;
(function (PlayerMessage) {
    PlayerMessage["Attack"] = "Player:Attack";
    PlayerMessage["DisplayAttack"] = "Player:DisplayAttack";
    PlayerMessage["LevelUp"] = "Player:LevelUp";
    PlayerMessage["Move"] = "Player:Move";
    PlayerMessage["ChangeMovingVector"] = "Player:ChangeMovingVector";
    PlayerMessage["Upgrade"] = "Player:Upgrade";
    PlayerMessage["UseCheatCode"] = "Player:UseCheatCode";
    PlayerMessage["UseSkill"] = "Player:UseSkill";
    PlayerMessage["PickupCrystal"] = "Player:PickupCrystal";
})(PlayerMessage || (PlayerMessage = {}));
