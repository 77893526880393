var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Battle } from './battle';
import { Assets } from './core/assets';
import { Client } from './core/client';
import { Device } from './core/device';
import { Input } from './core/input';
import { Language } from './core/language';
import { Logger } from './core/logger';
import { SDK } from './core/sdk';
import { Version } from './core/version';
import { Relay } from './relay';
import { RoomType } from '~/shared/core/room/types';
(() => __awaiter(void 0, void 0, void 0, function* () {
    Logger.addListeners();
    const language = Language.getType();
    Language.setDocumentTag(language);
    const support = Device.checkSupports();
    if (!support) {
        return;
    }
    const valid = yield Version.checkClientVersion();
    if (!valid) {
        return;
    }
    Device.addPlatformLabel();
    Device.addListeners();
    Client.setLoading('Loading resources');
    yield Promise.all([
        SDK.load(),
        Assets.load(),
    ]);
    Client.defineRoom(RoomType.Relay, Relay);
    Client.defineRoom(RoomType.Battle, Battle);
    Client.connect();
    Client.findAndJoinRoom();
    Input.addListeners();
    SDK.completeLoading();
}))();
