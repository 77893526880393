import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Control } from './control';
import { BUILDING_HOTKEY_UPGRADE, BUILDING_HOTKEY_REPAIR } from '../../../../const';
import { useBuilding } from '../../../hooks/use-building';
import { useEntityUpdate } from '~/client/battle/entity/ui/hooks/use-entity-update';
import { ActiveTutorialStepsState } from '~/client/battle/tutorial/ui/state';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Section } from '~/client/core/ui/components/section';
import { BuildingMessage } from '~/shared/battle/entity/building/types';
import { BuildingUtils } from '~/shared/battle/entity/building/utils';
import IconDestroy from './icons/destroy.svg';
import IconRepair from './icons/repair.svg';
import IconUpgrade from './icons/upgrade.svg';
export const Controls = () => {
    const battle = useRoom();
    const building = useBuilding();
    const activeTutorialSteps = useRecoilValue(ActiveTutorialStepsState);
    const [upgradeCost, setUpgradeCost] = useState(0);
    const [upgradeDisabled, setUpgradeDisabled] = useState(true);
    const [upgradeLevel, setUpgradeLevel] = useState(0);
    const [repairCost, setRepairCost] = useState(0);
    const [repairDisabled, setRepairDisabled] = useState(true);
    const blink = activeTutorialSteps.has(`Upgrade${building.schema.variant}`);
    useEntityUpdate(building, () => {
        setUpgradeCost(BuildingUtils.getUpgradeCost(building.schema));
        setUpgradeDisabled(BuildingUtils.isMaxLevel(building.schema));
        setUpgradeLevel(BuildingUtils.getLevelToUpgrade(building.schema, battle.state));
        setRepairCost(BuildingUtils.getRepairCost(building.schema));
        setRepairDisabled(building.schema.live.health === building.schema.live.maxHealth);
    });
    return (React.createElement(Section, { direction: "horizontal", gap: 6 },
        React.createElement(Control, { message: BuildingMessage.DoUpgrade, icon: IconUpgrade, hotkey: BUILDING_HOTKEY_UPGRADE, cost: upgradeCost, disabled: upgradeDisabled, minLevel: upgradeLevel, blink: blink }),
        React.createElement(Control, { message: BuildingMessage.DoRepair, icon: IconRepair, hotkey: BUILDING_HOTKEY_REPAIR, cost: repairCost, disabled: repairDisabled }),
        React.createElement(Control, { message: BuildingMessage.DoBreak, icon: IconDestroy })));
};
