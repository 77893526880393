import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';
import bin from './crab.bin';
import gltf from './crab.gltf';
Assets.addModel(ModelType.MobCrab, {
    gltf,
    bin: {
        origin: 'crab.bin',
        path: bin,
    },
});
