import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Icon } from '../icon';
import { Utils } from '~/shared/core/utils';
import styles from './styles.module.scss';
export const Amount = ({ icon, color, value, animated, postfix, size = 's', }) => {
    const refValue = useRef(null);
    useEffect(() => {
        const text = refValue.current;
        if (!animated || !text) {
            return;
        }
        const growth = Math.ceil(value / 200);
        let currentValue = 0;
        text.innerText = '0';
        const timer = setInterval(() => {
            currentValue = Math.min(currentValue + growth, value);
            text.innerText = currentValue.toString();
            if (currentValue === value) {
                clearInterval(timer);
            }
        }, 10);
        return () => {
            clearInterval(timer);
        };
    }, [value]);
    return (React.createElement("div", { className: cn(styles.wrapper, styles[size]) },
        React.createElement(Icon, { type: icon, className: styles.icon, color: color }),
        React.createElement("div", { className: styles.value },
            React.createElement("div", { ref: refValue, className: styles.amount }, Utils.decorateNumber(value)),
            postfix && (React.createElement("div", { className: styles.postfix }, postfix)))));
};
