import { CORPSE_DESTROY_TIMEOUT } from './const';
import { MaterialType } from '~/client/core/assets/materials/types';
import { Model } from '~/client/core/render-item/model';
export class Corpse extends Model {
    constructor(battle, config) {
        super(battle.scene, Object.assign(Object.assign({}, config), { material: MaterialType.Unit }));
        this.battle = battle;
        this.animator.play('die', {
            repeat: false,
            clamp: true,
        });
        this.battle.timeouts.add(() => {
            this.destroy();
        }, CORPSE_DESTROY_TIMEOUT);
    }
}
