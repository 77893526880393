export var TutorialStep;
(function (TutorialStep) {
    TutorialStep["Attack"] = "Attack";
    TutorialStep["UpgradePlayer"] = "UpgradePlayer";
    TutorialStep["UpgradeGenerator"] = "UpgradeGenerator";
    TutorialStep["UpgradeTowerFire"] = "UpgradeTowerFire";
    TutorialStep["BuildGenerator"] = "BuildGenerator";
    TutorialStep["BuildSecondGenerator"] = "BuildSecondGenerator";
    TutorialStep["BuildTowerFire"] = "BuildTowerFire";
    TutorialStep["BuildTowerFrost"] = "BuildTowerFrost";
    TutorialStep["BuildSpawnerSmall"] = "BuildSpawnerSmall";
    TutorialStep["BuildAmmunition"] = "BuildAmmunition";
    TutorialStep["BuildRadar"] = "BuildRadar";
})(TutorialStep || (TutorialStep = {}));
export var TutorialStepState;
(function (TutorialStepState) {
    TutorialStepState["Active"] = "Active";
    TutorialStepState["Completed"] = "Completed";
    TutorialStepState["Idle"] = "Idle";
})(TutorialStepState || (TutorialStepState = {}));
