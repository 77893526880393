import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { Param } from '..';
import { Icon } from '../../../icon';
import { Interactive } from '../../../interactive';
import { Option } from '../option';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Settings } from '~/client/core/settings';
import styles from './styles.module.scss';
import IconOptions from './icons/options.svg';
export const ParamSelect = ({ type, defaultValue, options, save = true, onChange, }) => {
    const language = useLanguage();
    const [value, setValue] = useState(defaultValue);
    const [opened, setOpened] = useState(false);
    const handleClick = useCallback(() => {
        setOpened(!opened);
    }, [opened]);
    const handleChange = (newValue) => {
        setOpened(false);
        setValue(newValue);
        onChange(newValue);
        if (save) {
            Settings.setEnum(type, newValue);
        }
    };
    return (React.createElement(Param, { type: type },
        React.createElement("div", { className: styles.wrapper },
            React.createElement(Interactive, { className: cn(styles.value, {
                    [styles.active]: opened,
                }), onClick: handleClick },
                language(value),
                React.createElement(Icon, { type: IconOptions, className: styles.icon })),
            opened && (React.createElement("div", { className: styles.options }, options.map((option) => (React.createElement(Option, { key: option, value: language(option), active: value === option, onClick: () => handleChange(option) }))))))));
};
