import React from 'react';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { SkillType } from '~/shared/battle/entity/unit/player/skill/types';
import styles from './styles.module.scss';
export const SkillInfo = ({ variant, skill }) => {
    const language = useLanguage();
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.name }, language(`${variant}Name`)),
        React.createElement("div", { className: styles.description }, language(`${variant}Description`)),
        React.createElement("div", { className: styles.addon }, skill.type === SkillType.Active
            ? language('SkillDuration', [skill.duration / 1000])
            : language('PassiveSkill'))));
};
