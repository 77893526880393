var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LoadingManager } from 'three';
import { AssetsAudios } from './audios';
import { AssetsMaterials } from './materials';
import { AssetsModels } from './models';
import { Client } from '~/client/core/client';
export class Assets {
    static load() {
        return __awaiter(this, void 0, void 0, function* () {
            const manager = new LoadingManager();
            manager.onProgress = (url, loaded, total) => {
                Client.setLoading(`Loading resources\n\n${Math.ceil((loaded / total) * 100)}%`);
            };
            yield Promise.all([
                ...AssetsModels.load(manager),
                ...AssetsAudios.load(manager),
            ]);
        });
    }
    /**
     * Models
     */
    static addModel(type, config) {
        return AssetsModels.add(type, config);
    }
    static getModel(type) {
        return AssetsModels.get(type);
    }
    static getClonedModel(type) {
        return AssetsModels.clone(type);
    }
    static getModelGeometry(type) {
        return AssetsModels.getGeometry(type);
    }
    /**
     * Materials
     */
    static addMaterial(type, material) {
        return AssetsMaterials.add(type, material);
    }
    static getMaterial(type) {
        return AssetsMaterials.get(type);
    }
    static createTexture(path) {
        return AssetsMaterials.createTexture(path);
    }
    /**
     * Audio
     */
    static addAudio(type, config) {
        return AssetsAudios.add(type, config);
    }
    static getAudio() {
        return AssetsAudios.get();
    }
}
