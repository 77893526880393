import { Easing, Tween } from '@tweenjs/tween.js';
import { MaterialType } from '~/client/core/assets/materials/types';
import { Circle } from '~/client/core/render-item/shape/circle';
import './resources';
export class AttackArea extends Circle {
    constructor(owner) {
        super(owner.battle.scene, {
            material: MaterialType.AttackArea,
            radius: 0.0,
            alpha: 0.2,
            visible: false,
        });
        this.tween = null;
        owner.renderItem.object.add(this.object);
    }
    update() {
        var _a;
        super.update();
        (_a = this.tween) === null || _a === void 0 ? void 0 : _a.update();
    }
    display({ radius, duration }) {
        if (this.tween) {
            this.hide();
        }
        this.setVisible(true);
        this.tween = new Tween({ radius: 0 }, false)
            .to({ radius }, duration)
            .easing(Easing.Quadratic.In)
            .onUpdate(({ radius }) => {
            this.setRadius(radius);
        })
            .onComplete(() => {
            this.hide();
        })
            .start();
    }
    hide() {
        if (!this.tween) {
            return;
        }
        this.tween.stop();
        this.tween = null;
        this.setVisible(false);
        this.setRadius(0);
    }
}
