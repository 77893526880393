import { Corpse } from './corpse';
import { MobUI } from './ui';
import { NPC } from '..';
import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { MOB_FREEZE_MULTIPLIER } from '~/shared/battle/entity/unit/npc/mob/const';
import { MobMessage } from '~/shared/battle/entity/unit/npc/mob/types';
import { BattleStage } from '~/shared/battle/types';
import { EventStream } from '~/shared/core/event-stream';
import { Utils } from '~/shared/core/utils';
import './resources';
export class Mob extends NPC {
    constructor(battle, config, schema) {
        super(battle, config, schema);
        this.freezen = false;
        this.baseMeshes = [];
        this.attackVariantsCount = 0;
        this.stateIcon = null;
        this.events = {
            onUpdate: new EventStream(),
            onChangeStateIcon: new EventStream(),
        };
        this.onBattleFinish = this.onBattleFinish.bind(this);
        this.renderItem.eachMeshes((mesh) => {
            this.baseMeshes.push(mesh);
        });
        this.modelType = config.model;
        const animations = Array.from(this.renderItem.animator.actions.keys());
        this.attackVariantsCount = animations.filter((action) => (action.indexOf('attack') === 0)).length;
        this.setUI(MobUI);
        this.createIndicator();
        this.createMarker();
        this.schema.live.listen('health', (health) => {
            if (health <= 0) {
                this.onDead();
            }
        });
        this.battle.events.onFinish.on(this.onBattleFinish);
    }
    destroy() {
        this.removeFrostEffect();
        if (this.renderItem.visible &&
            this.battle.state.stage === BattleStage.Started) {
            this.createCorpse();
        }
        this.battle.events.onFinish.off(this.onBattleFinish);
        super.destroy();
    }
    onBattleFinish() {
        this.renderItem.animator.paused = true;
    }
    createCorpse() {
        new Corpse(this.battle, {
            model: this.modelType,
            position: this.renderItem.position,
            rotation: this.renderItem.object.rotation,
        });
    }
    setStateIcon(icon) {
        this.stateIcon = icon;
        this.events.onChangeStateIcon.invoke(icon);
    }
    removeStateIcon() {
        this.stateIcon = null;
        this.events.onChangeStateIcon.invoke(null);
    }
    setBaseMaterial(type) {
        const material = Assets.getMaterial(type);
        this.baseMeshes.forEach((mesh) => {
            mesh.material = material;
        });
    }
    createFrostEffect() {
        if (this.freezen) {
            return;
        }
        this.renderItem.animator.timeScale = MOB_FREEZE_MULTIPLIER;
        this.freezen = true;
        this.renderItem.setMaterial(MaterialType.UnitFrost);
    }
    removeFrostEffect() {
        if (!this.freezen) {
            return;
        }
        this.renderItem.animator.timeScale = 1.0;
        this.freezen = false;
        this.renderItem.setMaterial(MaterialType.Unit);
    }
    handleMessages() {
        super.handleMessages();
        this.messages.on(MobMessage.Attack, ({ targetPosition }) => {
            this.onAttack(targetPosition);
        });
        this.messages.on(MobMessage.Freeze, ({ duration }) => {
            this.onFreeze(duration);
        });
    }
    onDead() {
        this.playAudio(AudioType.MobGore);
    }
    onAttack(targetPosition) {
        const variant = Utils.randomBetween(1, this.attackVariantsCount);
        this.renderItem.animator.play(`attack${variant}`, {
            repeat: false,
        });
        this.rotateToPosition(targetPosition);
    }
    onDisplayAttackArea(params) {
        super.onDisplayAttackArea(params);
        this.renderItem.animator.play('extra_attack', {
            repeat: false,
        });
        this.playAudio(AudioType.MobExtraAttack);
    }
    onFreeze(duration) {
        this.createFrostEffect();
        this.playAudio(AudioType.MobFrost);
        this.timeouts.add(() => {
            this.removeFrostEffect();
        }, duration);
    }
    onChangeMoveState(moving) {
        if (moving) {
            if (this.battle.state.stage === BattleStage.Finished) {
                return;
            }
            this.renderItem.animator.play('run');
        }
        else if (!this.freezen) {
            this.renderItem.animator.stop('run');
        }
    }
}
