import { LanguageType } from '../types';
import { KeyAction } from '~/client/battle/tutorial/ui/components/hints/content/key/types';
import { SwipeDirection } from '~/client/battle/tutorial/ui/components/hints/content/swipe/types';
import { SettingsType } from '~/client/core/settings/types';
import { PageType } from '~/client/relay/ui/components/pages/types';
import { BuildingCategory, BuildingVariant } from '~/shared/battle/entity/building/types';
import { DroidVariant } from '~/shared/battle/entity/unit/npc/droid/types';
import { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';
import { UpgradeCategory, UpgradeVariant } from '~/shared/battle/entity/unit/player/upgrades/types';
import { TutorialStep } from '~/shared/battle/tutorial/types';
import { BattleDifficult } from '~/shared/battle/types';
import { InventoryItemType } from '~/shared/core/inventory/item/types';
import { UserAuthError } from '~/shared/core/user/auth/types';
const PHRASES = {
    [PageType.Play]: 'Jouer',
    [PageType.Inventory]: 'Inventaire',
    [PageType.Leaderboard]: 'Classement',
    ToLogIn: 'Se connecter',
    ToSignUp: 'S’inscrire',
    LogIn: 'Connexion',
    SignUp: 'Inscription',
    Username: 'Nom d’utilisateur',
    Password: 'Mot de passe',
    AcceptTerms: 'J’accepte les conditions de',
    PrivacyPolicy: 'la politique de confidentialité',
    [UserAuthError.AlreadyAuthorized]: 'Vous êtes déjà autorisé',
    [UserAuthError.ExistUsername]: 'Le nom d’utilisateur spécifié est déjà pris',
    [UserAuthError.InvalidToken]: 'Jeton d’autorisation invalide',
    [UserAuthError.InvalidCredentials]: 'Identifiant ou mot de passe incorrect',
    [UserAuthError.InvalidUsername]: 'Nom d’utilisateur invalide',
    [UserAuthError.InvalidPassword]: 'Mot de passe invalide',
    MainMenu: 'Menu principal',
    Back: 'Retour',
    Continue: 'Continuer',
    Restart: 'Redémarrer',
    Settings: 'Paramètres',
    RestartConfirm: 'Êtes-vous sûr de vouloir recommencer depuis le début?',
    LeaveConfirm: 'Êtes-vous sûr de vouloir quitter ce combat?',
    LoadSave: 'Charger la sauvegarde',
    PlayAgain: 'Jouer encore',
    WaitingOpponent: 'En attente de l’adversaire...',
    OpponentDisconnected: 'L’adversaire a quitté la bataille',
    YouWin: 'Vous avez gagné',
    YouLose: 'Vous avez perdu',
    YouDied: 'Vous êtes mort',
    [`Difficult${BattleDifficult.Easy}`]: 'Mode Easy',
    [`Difficult${BattleDifficult.Easy}Description`]: 'Pour les débutants',
    [`Difficult${BattleDifficult.Hard}`]: 'Mode Difficile',
    [`Difficult${BattleDifficult.Hard}Description`]: 'Pour les joueurs expérimentés',
    PlayerOnline: 'Joueur en ligne',
    PlayersOnline: 'Joueurs en ligne',
    YouRanked: 'Vous êtes classé',
    PlaceOf: 'place sur',
    Earn: 'Obtenez',
    UnlockOnlineModes: 'pour déverrouiller les modes en ligne',
    SearchingOpponent: 'Recherche\nd’un adversaire',
    PlaySingle: 'Jeu solo',
    PlaySingleDescription: 'Jouer en mode vagues infinies',
    PlayOnline: 'Jeu en ligne',
    PlayOnlineDescription: 'Jouer avec un adversaire aléatoire',
    PlayOnlinePrivate: 'Jeu privé',
    PlayOnlinePrivateDescription: 'Jouer avec un ami',
    YourId: 'Votre ID',
    FriendId: 'ID de votre ami',
    Used: 'Utilisé',
    Select: 'Sélectionner',
    Selected: 'Sélectionné',
    Disable: 'Désactiver',
    Buy: 'Acheter',
    OrHold: 'Ou maintenir',
    Yes: 'Oui',
    No: 'Non',
    On: 'Activé',
    Off: 'Désactivé',
    Low: 'Bas',
    Medium: 'Moyen',
    High: 'Élevé',
    To: 'pour',
    [KeyAction.Press]: 'Appuyez',
    [KeyAction.Hold]: 'Maintenez',
    [`Swipe${SwipeDirection.Up}`]: 'Glissez vers le haut',
    [`Swipe${SwipeDirection.Left}`]: 'Glissez à gauche',
    [`Hint${TutorialStep.Attack}`]: 'attaquez les ennemis',
    [`Hint${TutorialStep.UpgradeGenerator}`]: 'Appuyez sur le générateur\npour l’améliorer',
    [`Hint${TutorialStep.UpgradeTowerFire}`]: 'Appuyez sur la tour\npour l’améliorer',
    [`Hint${TutorialStep.UpgradePlayer}`]: 'améliorer le personnage',
    [`Hint${TutorialStep.BuildGenerator}`]: 'construire un générateur',
    [`Hint${TutorialStep.BuildSecondGenerator}`]: 'construire un deuxième générateur',
    [`Hint${TutorialStep.BuildSpawnerSmall}`]: 'construire un invocateur de mobs',
    [`Hint${TutorialStep.BuildAmmunition}`]: 'construire des munitions',
    [`Hint${TutorialStep.BuildRadar}`]: 'construire un radar',
    [`Hint${TutorialStep.BuildTowerFire}`]: 'construire une tour de feu',
    [`Hint${TutorialStep.BuildTowerFrost}`]: 'construire une tour de givre',
    NotEnoughAmmo: 'Pas assez de munitions',
    NeedRepair: 'Besoin\nde réparation',
    Upgraded: 'Amélioré',
    PrepareForWave: 'Préparez-vous à l’attaque ennemie',
    BossWaveStarted: 'Boss wave started',
    WaveStarted: 'Vague %1 commencée',
    WaveCompleted: 'Vague %1 terminée',
    UntilWaveStart: 'Jusqu’au début\nde la vague',
    EnemiesLeft: 'Ennemis\nrestants',
    Experience: 'Expérience',
    Upgrades: 'Améliorations',
    MaxLevel: 'Niveau max',
    SkillDuration: 'Durée %1 s',
    SkillRecovery: 'Récupération %1 s',
    PassiveSkill: 'Passif',
    NewBuildingsAvailable: 'Nouveaux bâtiments\ndisponibles',
    EmptySlot: 'Emplacement vide',
    ExtraSlot: 'Emplacement supplémentaire',
    [`Category${InventoryItemType.Droid}`]: 'Droid',
    [`Category${InventoryItemType.Skill}`]: 'Compétences',
    [`${DroidVariant.Combat}Name`]: 'Combat',
    [`${DroidVariant.Combat}Description`]: 'Attaque les ennemis dans un certain rayon',
    [`${DroidVariant.Medic}Name`]: 'Médecin',
    [`${DroidVariant.Medic}Description`]: 'Soigne le personnage à intervalles réguliers',
    [`${DroidVariant.Mechanic}Name`]: 'Mécanicien',
    [`${DroidVariant.Mechanic}Description`]: 'Répare les bâtiments endommagés dans un certain rayon',
    [`${SkillVariant.Vampire}Name`]: 'Vampirisme',
    [`${SkillVariant.Vampire}Description`]: 'Restaure la santé avec 5% des dégâts infligés',
    [`${SkillVariant.BoostSpeed}Name`]: 'Augmentation de vitesse',
    [`${SkillVariant.BoostSpeed}Description`]: 'Augmente la vitesse de 50%',
    [`${SkillVariant.InspirationDroid}Name`]: 'Droid d’inspiration',
    [`${SkillVariant.InspirationDroid}Description`]: 'Augmente les caractéristiques du droid de 20%',
    [`${SkillVariant.InspirationMobs}Name`]: 'Inspiration des mobs',
    [`${SkillVariant.InspirationMobs}Description`]: 'Augmente les dégâts et la vitesse des mobs de 10%',
    [`${SkillVariant.InspirationTowers}Name`]: 'Inspiration des tours',
    [`${SkillVariant.InspirationTowers}Description`]: 'Augmente les dégâts et la vitesse d’attaque des tours de 20%',
    [`${SkillVariant.Sacrifice}Name`]: 'Sacrifice',
    [`${SkillVariant.Sacrifice}Description`]: 'Tue vos mobs et augmente votre santé',
    [`${SkillVariant.DiscountWalls}Name`]: 'Réduction des murs',
    [`${SkillVariant.DiscountWalls}Description`]: 'Réduit le coût des murs de 50%',
    [`${SkillVariant.DamageReflection}Name`]: 'Réflexion des dégâts',
    [`${SkillVariant.DamageReflection}Description`]: 'Réfléchit 10% des dégâts reçus',
    [`${SkillVariant.BoostGenerators}Name`]: 'Accélérer les générateurs',
    [`${SkillVariant.BoostGenerators}Description`]: 'Augmente la vitesse de génération de ressources de 10%',
    [`${SkillVariant.CallingMobs}Name`]: 'Invocation de mobs',
    [`${SkillVariant.CallingMobs}Description`]: 'Invoque des mobs près du personnage',
    [`${SkillVariant.AttackRecovery}Name`]: 'Attaques rapides',
    [`${SkillVariant.AttackRecovery}Description`]: 'Augmente la vitesse de récupération des attaques de 15%',
    [`${SkillVariant.HighDamage}Name`]: 'Dégâts élevés',
    [`${SkillVariant.HighDamage}Description`]: 'Augmente les dégâts du personnage de 15%',
    [`${SkillVariant.FrostWave}Name`]: 'Vague de Givre',
    [`${SkillVariant.FrostWave}Description`]: 'Gèle les mobs ennemis dans un certain rayon',
    [BuildingCategory.Defense]: 'Défense',
    [BuildingCategory.Spawners]: 'Générateurs',
    [BuildingCategory.Resources]: 'Ressources',
    [BuildingCategory.Towers]: 'Tours',
    [`${BuildingVariant.Wall}Name`]: 'Mur',
    [`${BuildingVariant.Ammunition}Name`]: 'Munitions',
    [`${BuildingVariant.Radar}Name`]: 'Radar',
    [`${BuildingVariant.Trap}Name`]: 'Piège',
    [`${BuildingVariant.Generator}Name`]: 'Générateur',
    [`${BuildingVariant.SpawnerSmall}Name`]: 'Générateur',
    [`${BuildingVariant.SpawnerMedium}Name`]: 'Générateur Supplémentaire',
    [`${BuildingVariant.SpawnerLarge}Name`]: 'Générateur Ultra',
    [`${BuildingVariant.TowerFire}Name`]: 'Tour de Feu',
    [`${BuildingVariant.TowerElectro}Name`]: 'Tour de Électro',
    [`${BuildingVariant.TowerFrost}Name`]: 'Tour de Givre',
    [`${BuildingVariant.TowerLaser}Name`]: 'Tour de Laser',
    [UpgradeCategory.Main]: 'Principal',
    [UpgradeCategory.Attack]: 'Attaque',
    [UpgradeCategory.Build]: 'Construction',
    [UpgradeVariant.MainMaxHealth]: 'Vie Max',
    [UpgradeVariant.MainSpeed]: 'Vitesse de déplacement',
    [UpgradeVariant.MainDroid]: 'Efficacité du Droid',
    [UpgradeVariant.AttackRecovery]: 'Récupération des Attaques',
    [UpgradeVariant.AttackRadius]: 'Rayon des Attaques',
    [UpgradeVariant.AttackDamage]: 'Dégâts',
    [UpgradeVariant.BuildSpeed]: 'Vitesse de Construction',
    [UpgradeVariant.BuildRadius]: 'Rayon de Construction',
    [SettingsType.FpsLimit]: 'Limite de FPS',
    [SettingsType.Resolution]: 'Qualité graphique',
    [SettingsType.VisualEffects]: 'Effets visuels',
    [SettingsType.AudioEffects]: 'Effets audio',
    [SettingsType.Fullscreen]: 'Plein écran',
    [SettingsType.Language]: 'Langue',
    [LanguageType.EN]: 'English',
    [LanguageType.RU]: 'Русский',
    [LanguageType.PT]: 'Português',
    [LanguageType.FR]: 'Français',
};
export default PHRASES;
