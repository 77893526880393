import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';
import audioElectro from './electro.mp3';
Assets.addAudio(AudioType.ShotElectro, {
    path: audioElectro,
    mode: AudioMode.Track3D,
    volume: 2.0,
    poolSize: 4,
    single: true,
});
