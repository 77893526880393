import { AudioListener } from 'three';
import { AudioTrack2D } from './track/track2d';
import { AudioTrack3D } from './track/track3d';
import { AudioMode } from './types';
import { Assets } from '../assets';
import { Logger } from '../logger';
export class Audio {
    constructor() {
        this.enabled = true;
        this.muffled = false;
        this.listener = new AudioListener();
        this.tracks3D = new Map();
        this.tracks2D = new Map();
        Assets.getAudio().forEach((data, type) => {
            if (data.mode === AudioMode.Track3D) {
                const track = new AudioTrack3D(type, this.listener, Object.assign(Object.assign({}, data), { buffers: Array.isArray(data.buffer) ? data.buffer : [data.buffer] }));
                this.tracks3D.set(type, track);
            }
            else {
                const track = new AudioTrack2D(type, this.listener, Object.assign(Object.assign({}, data), { buffer: Array.isArray(data.buffer) ? data.buffer[0] : data.buffer }));
                this.tracks2D.set(type, track);
            }
        });
    }
    destroy() {
        this.removeTracks(this.tracks2D);
        this.removeTracks(this.tracks3D);
    }
    muffle(state) {
        if (this.muffled === state) {
            return;
        }
        this.muffled = state;
        this.refresh();
    }
    toggle(state) {
        if (this.enabled === state) {
            return;
        }
        this.enabled = state;
        this.refresh();
    }
    refresh() {
        const tracks = [
            ...this.tracks2D.values(),
            ...this.tracks3D.values(),
        ];
        tracks.forEach((track) => {
            if (this.enabled && !this.muffled) {
                track.enable();
            }
            else {
                track.disable();
            }
        });
    }
    // TODO: Check posing
    setTarget(target) {
        target.object.add(this.listener);
    }
    play2D(type) {
        const track = this.tracks2D.get(type);
        if (!track) {
            Logger.warn(`Unable to play unregistered 2D audio '${type}'`);
            return;
        }
        track.play();
    }
    play3D(type, data) {
        const track = this.tracks3D.get(type);
        if (!track) {
            Logger.warn(`Unable to play unregistered 3D audio '${type}'`);
            return;
        }
        track.play(data);
    }
    stop(type) {
        var _a;
        const track = ((_a = this.tracks3D.get(type)) !== null && _a !== void 0 ? _a : this.tracks2D.get(type));
        track === null || track === void 0 ? void 0 : track.stop();
    }
    removeTracks(target) {
        target.forEach((track, type) => {
            track.destroy();
            target.delete(type);
        });
    }
}
