import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { useBuilding } from '../../../hooks/use-building';
import { ProgressBar } from '~/client/battle/entity/ui/components/progress-bar';
export const HealthBar = () => {
    const building = useBuilding();
    const health = useSchemaValue(building.schema.live, 'health');
    const maxHealth = useSchemaValue(building.schema.live, 'maxHealth');
    return (React.createElement(ProgressBar, { value: health, maxValue: maxHealth, color: building.selfOwn ? '#4ed83e' : '#ff5151' }));
};
