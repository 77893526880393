export var RelayRequest;
(function (RelayRequest) {
    RelayRequest["StartSearchingOpponent"] = "StartSearchingOpponent";
    RelayRequest["StopSearchingOpponent"] = "StopSearchingOpponent";
    RelayRequest["PlaySingle"] = "PlaySingle";
    RelayRequest["GenerateFriendId"] = "GenerateFriendId";
    RelayRequest["ClearFriendId"] = "ClearFriendId";
    RelayRequest["JoinFriendId"] = "JoinFriendId";
})(RelayRequest || (RelayRequest = {}));
export var RelayMessage;
(function (RelayMessage) {
    RelayMessage["JoinBattle"] = "Relay:JoinBattle";
})(RelayMessage || (RelayMessage = {}));
