import React, { useState } from 'react';
import { Icon } from '../icon';
import styles from './styles.module.scss';
export const Input = ({ label, icon, validate, onChange, maxLength, type = 'text' }) => {
    const [value, setValue] = useState('');
    const handleChange = (event) => {
        const newValue = event.target.value;
        if (!validate || validate(newValue)) {
            setValue(newValue);
            onChange(newValue);
        }
    };
    return (React.createElement("div", { className: styles.input },
        React.createElement("input", { type: type, placeholder: label, onChange: handleChange, value: value, maxLength: maxLength }),
        React.createElement(Icon, { type: icon, className: styles.icon })));
};
