export class Throttle {
    constructor(delay) {
        this.scale = 1.0;
        this.delay = delay;
        this.targetTime = performance.now();
    }
    call(callback) {
        const timeleft = this.targetTime - performance.now();
        if (timeleft > 0) {
            return;
        }
        callback();
        const delay = Math.round(this.delay * this.scale);
        this.targetTime = performance.now() + delay;
    }
}
