import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import styles from './styles.module.scss';
import IconPlayers from './icons/players.svg';
export const PlayersOnline = () => {
    const room = useRoom();
    const language = useLanguage();
    const online = useSchemaValue(room.state, 'online');
    return (React.createElement(Section, { direction: "horizontal", align: "center", gap: 16, className: styles.container },
        React.createElement(IconPlayers, null),
        React.createElement(Section, { direction: "horizontal", align: "center", gap: 6 },
            React.createElement(Text, { size: "s", bold: true }, online),
            React.createElement(Text, { size: "xs", uppercase: true }, online === 1 ? language('PlayerOnline') : language('PlayersOnline')))));
};
