import { Mesh, SkinnedMesh } from 'three';
import { GLTFLoader } from './loader';
export class AssetsModels {
    static add(type, { gltf, bin }) {
        if (this.list.has(type)) {
            throw Error(`Model '${type}' is already registered`);
        }
        this.list.set(type, gltf);
        const binPathParts = bin.path.split('/');
        this.binaries.set(bin.origin, binPathParts[binPathParts.length - 1]);
    }
    static get(type) {
        const model = this.models.get(type);
        if (!model) {
            throw Error(`Unregistered model '${type}'`);
        }
        return model;
    }
    static clone(type) {
        const model = this.get(type);
        const object = model.scene.clone();
        const sourceLookup = new Map();
        const cloneLookup = new Map();
        const parallelTraverse = (a, b, callback) => {
            callback(a, b);
            for (let i = 0; i < a.children.length; i++) {
                parallelTraverse(a.children[i], b.children[i], callback);
            }
        };
        parallelTraverse(model.scene, object, (source, clone) => {
            sourceLookup.set(clone, source);
            cloneLookup.set(source, clone);
        });
        object.traverse((mesh) => {
            if (mesh instanceof SkinnedMesh) {
                const sourceMesh = sourceLookup.get(mesh);
                if (sourceMesh) {
                    const sourceBones = sourceMesh.skeleton.bones;
                    mesh.skeleton = sourceMesh.skeleton.clone();
                    mesh.bindMatrix.copy(sourceMesh.bindMatrix);
                    mesh.skeleton.bones = sourceBones.map((bone) => cloneLookup.get(bone));
                    mesh.bind(mesh.skeleton, mesh.bindMatrix);
                }
            }
        });
        return object;
    }
    static load(manager) {
        const loaderModel = new GLTFLoader(manager);
        const tasks = Array(this.list.size);
        this.list.forEach((path, type) => {
            tasks.push(loaderModel.loadAsync(path).then((model) => {
                this.models.set(type, model);
            }));
        });
        return tasks;
    }
    static getGeometry(type) {
        let geometry = null;
        this.get(type).scene.traverse((mesh) => {
            if (mesh instanceof Mesh) {
                geometry = mesh.geometry;
            }
        });
        return geometry;
    }
}
AssetsModels.list = new Map();
AssetsModels.models = new Map();
AssetsModels.binaries = new Map();
