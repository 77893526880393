import cn from 'classnames';
import React from 'react';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Scrollbar } from '~/client/core/ui/components/scrollbar';
import { Text } from '~/client/core/ui/components/text';
import styles from './styles.module.scss';
import IconBack from './icons/back.svg';
export const InventoryPanel = ({ title, children, stretch, onClose, }) => {
    const language = useLanguage();
    return (React.createElement("div", { className: cn(styles.container, {
            [styles.stretch]: stretch,
        }) },
        React.createElement("div", { className: styles.header },
            React.createElement(Text, { size: "xl", bold: true, uppercase: true }, title),
            onClose && (React.createElement(Interactive, { className: styles.back, onClick: onClose },
                React.createElement(Icon, { type: IconBack, className: styles.icon }),
                language('Back')))),
        React.createElement(Scrollbar, null,
            React.createElement("div", { className: styles.list }, children))));
};
