import { Vector3, Raycaster, DirectionalLight } from 'three';
import { BATTLE_SCENE_BACKGROUND_COLOR, BATTLE_SCENE_CAMERA_ANGLE, BATTLE_SCENE_CAMERA_DISTANCE, BATTLE_SCENE_LIGHT } from './const';
import { SceneLayer } from './types';
import { BUILDING_MODELS } from '../entity/building/const';
import { MaterialType } from '~/client/core/assets/materials/types';
import { Device } from '~/client/core/device';
import { InputMouse } from '~/client/core/input/mouse';
import { InputTouch } from '~/client/core/input/touch';
import { Scene } from '~/client/core/scene';
import { Snap } from '~/client/core/snap';
import { DeviceType } from '~/shared/core/device/types';
export class BattleScene extends Scene {
    constructor(battle) {
        super({
            light: BATTLE_SCENE_LIGHT,
            backgroundColor: BATTLE_SCENE_BACKGROUND_COLOR,
        });
        this.buildingsLinks = new Map();
        this.selectedBuilding = null;
        this.hoveredBuilding = null;
        this.snaps = new Map();
        this.raycaster = new Raycaster();
        this.onMouseClick = this.onMouseClick.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onTouchWorld = this.onTouchWorld.bind(this);
        this.battle = battle;
        this.raycaster.layers.enable(SceneLayer.Building);
        this.camera.layers.disable(SceneLayer.Marker);
        this.camera.setAngle(BATTLE_SCENE_CAMERA_ANGLE);
        this.camera.setDistance(BATTLE_SCENE_CAMERA_DISTANCE);
        if (Device.type === DeviceType.Mobile) {
            this.camera.setTargetOffset(-0.5);
        }
        this.generateBuildingsPreview();
        this.addLocalLight();
        this.battle.state.listen('paused', (paused) => {
            this.paused = paused;
        });
    }
    destroy() {
        this.toggleControls(false);
        super.destroy();
    }
    toggleControls(state) {
        const method = state ? 'on' : 'off';
        if (Device.type === DeviceType.Mobile) {
            InputTouch.events.onTouchWorld[method](this.onTouchWorld);
        }
        else {
            InputMouse.events.onMouseClickWorld[method](this.onMouseClick);
            InputMouse.events.onMouseMoveWorld[method](this.onMouseMove);
        }
    }
    onTouchWorld(touch) {
        const building = this.getBuildingByNormalizedPosition(touch.normalizedPosition);
        if (building === this.selectedBuilding) {
            return;
        }
        touch.events.onRelease.on(() => {
            if (touch.shifted) {
                return;
            }
            if (this.selectedBuilding) {
                this.selectedBuilding.clickOutside();
                this.selectedBuilding = null;
            }
            if (building &&
                // TODO: Unsubscribe from event on building destroy
                !building.disposed) {
                const toHandle = building.click();
                if (toHandle) {
                    this.selectedBuilding = building;
                }
            }
        });
    }
    onMouseClick() {
        if (this.hoveredBuilding) {
            if (this.hoveredBuilding === this.selectedBuilding) {
                return;
            }
            if (this.selectedBuilding) {
                this.selectedBuilding.clickOutside();
                this.selectedBuilding = null;
            }
            const toHandle = this.hoveredBuilding.click();
            if (toHandle) {
                this.selectedBuilding = this.hoveredBuilding;
            }
        }
        else if (this.selectedBuilding) {
            this.selectedBuilding.clickOutside();
            this.selectedBuilding = null;
        }
    }
    onMouseMove() {
        const building = this.getBuildingByNormalizedPosition(InputMouse.normalizedPosition);
        if (building === this.hoveredBuilding) {
            return;
        }
        if (this.hoveredBuilding) {
            this.hoveredBuilding.unhover();
            this.hoveredBuilding = null;
        }
        if (building) {
            this.hoveredBuilding = building;
            this.hoveredBuilding.hover();
        }
        InputMouse.setCursorPointer(Boolean(this.hoveredBuilding));
    }
    getBuildingByNormalizedPosition(position) {
        var _a;
        let building = null;
        this.raycaster.setFromCamera(position, this.camera);
        const [intersect] = this.raycaster.intersectObject(this);
        if (intersect) {
            let parent = intersect.object;
            while (!building) {
                if (!parent) {
                    break;
                }
                building = (_a = this.buildingsLinks.get(parent.uuid)) !== null && _a !== void 0 ? _a : null;
                parent = parent.parent;
            }
        }
        return building;
    }
    addLocalLight() {
        const directionalLight = new DirectionalLight(0xffffff, 1.0);
        directionalLight.position.set(0, 10, 5);
        this.add(directionalLight);
    }
    generateBuildingsPreview() {
        const snap = new Snap({
            camera: new Vector3(6, 3, 6),
            width: 60,
            height: 80,
        });
        Object.entries(BUILDING_MODELS).forEach(([variant, model]) => {
            const object = snap.addModel({
                model,
                material: MaterialType.Building,
            });
            this.snaps.set(variant, snap.export());
            snap.remove(object);
        });
        snap.remove();
    }
}
