import { BuildingTower } from '..';
import { ModelType } from '~/client/core/assets/types';
import './resources';
export class BuildingTowerFire extends BuildingTower {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.BuildingTowerFire,
        }, schema);
    }
}
