import React, { useEffect } from 'react';
import { Category } from './category';
import { BUILDER_TOGGLE_KEY } from './const';
import { ScreenGrid } from '~/client/battle/ui/components/stages/started/screen-grid';
import { useScreen } from '~/client/battle/ui/hooks/use-screen';
import { ScreenType } from '~/client/battle/ui/types';
import { Device } from '~/client/core/device';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { InputTouch } from '~/client/core/input/touch';
import { InputTouchSwipeDirection } from '~/client/core/input/touch/types';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { BuildingCategory } from '~/shared/battle/entity/building/types';
import { DeviceType } from '~/shared/core/device/types';
export const BuilderScreen = () => {
    const { builder } = useRoom();
    const { screen, openScreen, closeScreen } = useScreen();
    const visible = screen === ScreenType.Builder;
    const categories = Object.values(BuildingCategory);
    const toggleVisible = (state) => {
        if (visible === state) {
            return;
        }
        if (state) {
            openScreen(ScreenType.Builder);
        }
        else {
            closeScreen();
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === BUILDER_TOGGLE_KEY) {
            event.preventDefault();
            toggleVisible(true);
        }
    };
    const handleKeyUp = (event) => {
        if (event.key === BUILDER_TOGGLE_KEY) {
            event.preventDefault();
            toggleVisible(false);
        }
    };
    const handleTouchSwipe = (event) => {
        if (builder.isBuild()) {
            return;
        }
        if (event.direction === InputTouchSwipeDirection.Left) {
            toggleVisible(true);
        }
        else if (event.direction === InputTouchSwipeDirection.Right) {
            toggleVisible(false);
        }
    };
    const handleSelect = () => {
        toggleVisible(false);
    };
    useEffect(() => {
        if (visible) {
            builder.setVariant(null);
        }
    }, [visible]);
    useEffect(() => {
        if (Device.type === DeviceType.Mobile) {
            const eventTouchSwipe = InputTouch.events.onTouchSwipe.on(handleTouchSwipe);
            return () => {
                eventTouchSwipe.off();
            };
        }
        else {
            const eventKeyUp = InputKeyboard.events.onKeyUp.on(handleKeyUp);
            const eventKeyDown = InputKeyboard.events.onKeyDown.on(handleKeyDown);
            return () => {
                eventKeyUp.off();
                eventKeyDown.off();
            };
        }
    }, [visible]);
    return visible && (React.createElement(ScreenGrid, null, categories.map((category) => (React.createElement(Category, { key: category, category: category, onSelect: handleSelect })))));
};
