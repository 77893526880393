import { AttackArea } from './attack-area';
import { UNIT_ROTATE_LERP } from './const';
import { Indicator } from './indicator';
import { Entity } from '..';
import { SceneLayer } from '../../scene/types';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { Logger } from '~/client/core/logger';
import { Model } from '~/client/core/render-item/model';
import { LiveDamageType } from '~/shared/battle/entity/live/types';
import { UnitMessage } from '~/shared/battle/entity/unit/types';
import { VectorUtils } from '~/shared/core/vector-utils';
import './resources';
export class Unit extends Entity {
    constructor(battle, config, schema) {
        const renderItem = new Model(battle.scene, Object.assign(Object.assign({}, config), { material: MaterialType.Unit }));
        renderItem.eachMeshes((mesh) => {
            mesh.layers.set(SceneLayer.Unit);
        });
        super(battle, renderItem, schema);
        this.indicator = null;
        this.attackArea = null;
        this.handleMessages();
    }
    destroy() {
        this.removeIndicator();
        this.removeAttackArea();
        super.destroy();
    }
    onSceneUpdate() {
        super.onSceneUpdate();
        this.rotateToVelocity();
    }
    createAttackArea() {
        if (this.attackArea) {
            Logger.warn('Unit attack area is already created');
            return;
        }
        this.attackArea = new AttackArea(this);
    }
    removeAttackArea() {
        if (!this.attackArea) {
            return;
        }
        this.attackArea.destroy();
        this.attackArea = null;
    }
    createIndicator() {
        if (this.indicator) {
            Logger.warn('Unit indicator is already created');
            return;
        }
        this.indicator = new Indicator(this);
    }
    removeIndicator() {
        if (!this.indicator) {
            return;
        }
        this.indicator.destroy();
        this.indicator = null;
    }
    rotate(angle) {
        this.renderItem.rotate(angle, UNIT_ROTATE_LERP);
    }
    rotateToVelocity() {
        const velocity = VectorUtils.reuse(this.velocity).normalize();
        if (velocity.length() > 0) {
            const angle = Math.atan2(velocity.x, velocity.z);
            this.rotate(angle);
        }
    }
    rotateToPosition(position) {
        const vector = VectorUtils.reuse(position).sub(this.renderItem.position);
        const angle = Math.atan2(vector.x, vector.z);
        this.rotate(angle);
    }
    handleMessages() {
        this.messages.on(UnitMessage.DisplayAttackArea, (payload) => {
            this.onDisplayAttackArea(payload);
        });
    }
    onDisplayAttackArea(params) {
        var _a;
        if (!this.attackArea) {
            this.createAttackArea();
        }
        (_a = this.attackArea) === null || _a === void 0 ? void 0 : _a.display(params);
    }
    onDamage({ type }) {
        let audio = null;
        switch (type) {
            case LiveDamageType.Fire: {
                audio = AudioType.UnitHitFire;
                break;
            }
            case LiveDamageType.Bite: {
                audio = AudioType.UnitHitBite;
                break;
            }
            case LiveDamageType.Punch: {
                audio = AudioType.UnitHitPunch;
                break;
            }
            default: {
                return;
            }
        }
        this.battle.scene.audio.play3D(audio, {
            parent: this.battle.scene,
            position: this.renderItem.position,
        });
    }
}
