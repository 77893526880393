// TODO: Move to core
export var SceneLayer;
(function (SceneLayer) {
    SceneLayer[SceneLayer["Liquid"] = 1] = "Liquid";
    SceneLayer[SceneLayer["Ground"] = 2] = "Ground";
    SceneLayer[SceneLayer["Mounts"] = 3] = "Mounts";
    SceneLayer[SceneLayer["Unit"] = 4] = "Unit";
    SceneLayer[SceneLayer["Building"] = 5] = "Building";
    SceneLayer[SceneLayer["Marker"] = 6] = "Marker";
    SceneLayer[SceneLayer["Fog"] = 7] = "Fog";
    SceneLayer[SceneLayer["Misc"] = 8] = "Misc";
})(SceneLayer || (SceneLayer = {}));
