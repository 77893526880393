import cn from 'classnames';
import React from 'react';
import { SwipeDirection } from './types';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import styles from './styles.module.scss';
import IconSwipeLeft from './icons/swipe-left.svg';
import IconSwipeUp from './icons/swipe-up.svg';
export const Swipe = ({ direction, children }) => {
    const language = useLanguage();
    return (React.createElement("div", { className: cn(styles.wrapper, styles[direction.toLowerCase()]) },
        direction === SwipeDirection.Left ? (React.createElement(IconSwipeLeft, { className: styles.icon })) : (React.createElement(IconSwipeUp, { className: styles.icon })),
        React.createElement("div", { className: styles.text },
            React.createElement("p", { className: styles.large }, language(`Swipe${direction}`)),
            React.createElement("p", null,
                language('To'),
                " ",
                children))));
};
