import cn from 'classnames';
import React, { useRef, useLayoutEffect } from 'react';
import { Device } from '~/client/core/device';
import { useEvent } from '~/client/core/event-steam/ui/hooks/use-event';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import styles from './styles.module.scss';
export const Translator = ({ renderItem, className, head, body }) => {
    const room = useRoom();
    const refContainer = useRef(null);
    useEvent(room.scene.events.onRender, () => {
        const container = refContainer.current;
        if (!container) {
            return;
        }
        if (!renderItem.visible) {
            container.style.display = 'none';
            return;
        }
        container.style.display = '';
        container.style.opacity = String(renderItem.getMaterial().opacity);
        const position = renderItem.getPositionOnScreen();
        container.style.left = `${position.x}px`;
        container.style.top = `${position.y}px`;
        const scale = Math.pow(Device.getScale(), 0.75);
        const size = renderItem.getSizeOnScreen();
        container.style.transform = `translate(-50%, -100%) scale(${scale})`;
        container.style.width = `${size.x}px`;
        container.style.height = `${size.y}px`;
    }, []);
    useLayoutEffect(() => {
        if (refContainer.current && !renderItem.visible) {
            refContainer.current.style.display = 'none';
        }
    }, []);
    return (React.createElement("div", { ref: refContainer, className: cn(styles.translator, className) },
        React.createElement("div", { className: styles.wrapper },
            head && React.createElement("div", { className: styles.head }, head),
            body && React.createElement("div", { className: styles.body }, body))));
};
