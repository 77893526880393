import { Mob } from '../..';
import { MaterialType } from '~/client/core/assets/materials/types';
import { ModelType } from '~/client/core/assets/types';
import IconUnvisible from './icons/unvisible.svg';
import './resources';
export class MobStingray extends Mob {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.MobStingray,
        }, schema);
    }
    onSceneUpdate() {
        super.onSceneUpdate();
        if (!this.selfOwn) {
            this.updateUnvisibleState();
        }
    }
    isUnvisible() {
        return !this.selfOwn && this.schema.unvisible;
    }
    updateUnvisibleState() {
        if (this.isUnvisible()) {
            this.setBaseMaterial(MaterialType.UnitTransparent);
            this.setStateIcon(IconUnvisible);
        }
        else {
            this.setBaseMaterial(MaterialType.Unit);
            this.removeStateIcon();
        }
    }
}
