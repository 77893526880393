import { DECORATION_PRESETS } from './const';
import { MeshSetChild } from '~/client/core/render-item/mesh-set/child';
import { Utils } from '~/shared/core/utils';
import './resources';
export class Decoration extends MeshSetChild {
    constructor(schema) {
        const scales = DECORATION_PRESETS[schema.type].scale;
        super({
            position: schema.position,
            scale: Utils.randomFloatBetween(...scales),
            rotation: 2 * Math.PI * Math.random(),
        });
        schema.onRemove(() => {
            this.hide();
        });
    }
    static getModel(schema) {
        const model = DECORATION_PRESETS[schema.type].models[schema.index];
        if (!model) {
            throw Error('Invalid decoration model index');
        }
        return model;
    }
}
