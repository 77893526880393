import React from 'react';
import { useRecoilState } from 'recoil';
import { ParamBoolean } from './params/param-boolean';
import { ParamEnum } from './params/param-enum';
import { ParamInteger } from './params/param-integer';
import { ParamSelect } from './params/param-select';
import { Language } from '~/client/core/language';
import { LanguageType } from '~/client/core/language/types';
import { LanguageState } from '~/client/core/language/ui/state';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { SceneResolution } from '~/client/core/scene/types';
import { Settings } from '~/client/core/settings';
import { SettingsType } from '~/client/core/settings/types';
import { Section } from '~/client/core/ui/components/section';
export const SettingsPanel = () => {
    const room = useRoom();
    const [language, setLanguage] = useRecoilState(LanguageState);
    const handleChangeLanguage = (value) => {
        setLanguage(value);
        Language.setDocumentTag(value);
    };
    const handleChangeFpsLimit = (value) => {
        room.scene.setFpsLimit(value);
    };
    const handleChangeResolution = (value) => {
        room.scene.setResolution(value);
    };
    const handleChangeFullscreen = (value) => {
        room.scene.toggleFullscreen(value);
    };
    const handleChangeAudioEffects = (value) => {
        room.scene.audio.toggle(value);
    };
    return (React.createElement(Section, { direction: "vertical", gap: 32 },
        React.createElement(ParamSelect, { type: SettingsType.Language, onChange: handleChangeLanguage, defaultValue: language, options: Object.values(LanguageType) }),
        React.createElement(ParamBoolean, { type: SettingsType.AudioEffects, onChange: handleChangeAudioEffects, defaultValue: Settings.getBoolean(SettingsType.AudioEffects, true) }),
        document.fullscreenEnabled && (React.createElement(ParamBoolean, { type: SettingsType.Fullscreen, onChange: handleChangeFullscreen, defaultValue: room.scene.isFullscreen(), store: false })),
        React.createElement(ParamEnum, { type: SettingsType.Resolution, onChange: handleChangeResolution, defaultValue: room.scene.resolution, options: Object.values(SceneResolution) }),
        React.createElement(ParamInteger, { type: SettingsType.FpsLimit, onChange: handleChangeFpsLimit, defaultValue: room.scene.fpsLimit, options: [30, 45, 60] })));
};
