import React from 'react';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';
import { SettingsPanel } from '~/client/core/ui/components/settings-panel';
import styles from './styles.module.scss';
export const OverlaySettings = () => {
    const language = useLanguage();
    return (React.createElement(Section, { direction: 'vertical', align: 'center', gap: 64 },
        React.createElement("div", { className: styles.title }, language('Settings')),
        React.createElement(SettingsPanel, null)));
};
