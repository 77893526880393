export var BattleMode;
(function (BattleMode) {
    BattleMode["Online"] = "Online";
    BattleMode["Single"] = "Single";
})(BattleMode || (BattleMode = {}));
export var BattleDifficult;
(function (BattleDifficult) {
    BattleDifficult["Easy"] = "Easy";
    BattleDifficult["Hard"] = "Hard";
})(BattleDifficult || (BattleDifficult = {}));
export var BattleStage;
(function (BattleStage) {
    BattleStage["Preparing"] = "Preparing";
    BattleStage["Started"] = "Started";
    BattleStage["Finished"] = "Finished";
})(BattleStage || (BattleStage = {}));
/**
 * Messages
 */
export var BattleMessage;
(function (BattleMessage) {
    BattleMessage["Ping"] = "Battle:Ping";
    BattleMessage["TogglePause"] = "Battle:TogglePause";
    BattleMessage["Restart"] = "Battle:Restart";
})(BattleMessage || (BattleMessage = {}));
