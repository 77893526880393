import cn from 'classnames';
import React, { useCallback } from 'react';
import { Text } from '../text';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
export const Checkbox = ({ children, onChange, checked = false, }) => {
    const handleClick = useCallback(() => {
        onChange(!checked);
    }, [checked]);
    return (React.createElement("div", { className: styles.checkbox },
        React.createElement(Interactive, { onClick: handleClick, className: cn(styles.marker, {
                [styles.checked]: checked,
            }) }),
        React.createElement(Text, { size: 's' }, children)));
};
