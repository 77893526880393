import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { Parameter } from './parameter';
import { useSelfPlayerSchema } from '~/client/battle/ui/hooks/use-self-player-schema';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { BattleMode } from '~/shared/battle/types';
import styles from './styles.module.scss';
export const PlayerInfoPanel = () => {
    const battle = useRoom();
    const player = useSelfPlayerSchema();
    const language = useLanguage();
    const health = useSchemaValue(player.live, 'health');
    const resources = useSchemaValue(player, 'resources');
    const ammo = useSchemaValue(player, 'ammo');
    const mobs = useSchemaValue(player, 'mobsCount');
    const onlineMode = battle.state.mode === BattleMode.Online;
    return (React.createElement("div", { className: styles.parameters },
        React.createElement(Parameter, { type: "health", value: health }),
        React.createElement(Parameter, { type: "resources", value: resources }),
        React.createElement(Parameter, { type: "ammo", value: ammo, alert: language('NotEnoughAmmo') }),
        onlineMode && (React.createElement(Parameter, { type: "mobs", value: mobs }))));
};
