export var UserAuthError;
(function (UserAuthError) {
    UserAuthError["InvalidCredentials"] = "InvalidCredentials";
    UserAuthError["InvalidToken"] = "InvalidToken";
    UserAuthError["InvalidUsername"] = "InvalidUsername";
    UserAuthError["InvalidPassword"] = "InvalidPassword";
    UserAuthError["ExistUsername"] = "ExistUsername";
    UserAuthError["AlreadyAuthorized"] = "AlreadyAuthorized";
})(UserAuthError || (UserAuthError = {}));
export var UserAuthRequest;
(function (UserAuthRequest) {
    UserAuthRequest["Register"] = "UserAuth:Register";
    UserAuthRequest["Login"] = "UserAuth:Login";
    UserAuthRequest["Logout"] = "UserAuth:Logout";
})(UserAuthRequest || (UserAuthRequest = {}));
