import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect } from 'react';
import { Entities } from './components/entities';
import { BATTLE_STAGE_COMPONENT_MAP } from './const';
import { useScreen } from './hooks/use-screen';
import { BuildControls } from '../builder/ui/components/build-controls';
import { Device } from '~/client/core/device';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Storage } from '~/client/core/storage';
import { useRelativeScreen } from '~/client/core/ui/hooks/use-relative-screen';
import { BattleStage } from '~/shared/battle/types';
import { DeviceType } from '~/shared/core/device/types';
import styles from './styles.module.scss';
export const BattleScreen = () => {
    var _a;
    const { state } = useRoom();
    const { openScreen } = useScreen();
    const stage = (_a = useSchemaValue(state, 'stage')) !== null && _a !== void 0 ? _a : BattleStage.Preparing;
    const StageComponent = BATTLE_STAGE_COMPONENT_MAP[stage];
    const refScreen = useRelativeScreen();
    useEffect(() => {
        const savedScreen = Storage.get('Screen');
        if (savedScreen) {
            openScreen(savedScreen);
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: refScreen, className: styles.screen }, StageComponent && React.createElement(StageComponent, null)),
        stage !== BattleStage.Finished && (React.createElement(React.Fragment, null,
            React.createElement(Entities, null),
            Device.type === DeviceType.Mobile && (React.createElement(BuildControls, null))))));
};
