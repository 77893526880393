import cn from 'classnames';
import React, { useCallback } from 'react';
import { INVENTORY_ITEM_ICON } from '../../const';
import { useInventorySelection } from '../../hooks/use-inventory-selection';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { useUserData } from '~/client/relay/ui/hooks/use-user-data';
import { InventoryRequest } from '~/shared/core/inventory/types';
import { InventoryUtils } from '~/shared/core/inventory/utils';
import styles from './styles.module.scss';
import IconLock from './icons/lock.svg';
export const InventoryItem = ({ item, have }) => {
    const room = useRoom();
    const language = useLanguage();
    const { slot, closeSelection } = useInventorySelection();
    const inventory = useUserData('inventory');
    const crystals = useUserData('crystals');
    const itemCurrentSlot = InventoryUtils.getItemSlot(inventory, item);
    const active = itemCurrentSlot === slot;
    const cost = InventoryUtils.getItemCost(item);
    const allow = crystals >= cost;
    const handleClick = useCallback(() => {
        if (have) {
            if (active) {
                closeSelection();
                return;
            }
            room.sendRequest(InventoryRequest.UpdateSlot, { item, slot })
                .then(closeSelection);
        }
        else {
            if (!allow) {
                return false;
            }
            room.sendRequest(InventoryRequest.BuyItem, { item });
        }
    }, [have, active, allow]);
    return (React.createElement(Interactive, { className: cn(styles.wrapper, {
            [styles.have]: have,
            [styles.active]: active,
            [styles.allow]: allow,
        }), onClick: handleClick },
        React.createElement("div", { className: styles.meta },
            React.createElement("div", { className: styles.frame },
                React.createElement(Icon, { type: INVENTORY_ITEM_ICON[item], className: styles.icon })),
            !have && (React.createElement("div", { className: styles.cost },
                React.createElement(Crystals, { value: cost })))),
        React.createElement(Section, { direction: 'vertical', gap: 8 },
            React.createElement(Section, { direction: 'horizontal', align: 'center', gap: 8 },
                !have && (React.createElement(IconLock, { className: styles.lock })),
                React.createElement(Text, { view: 'primary', size: 'l', bold: true, uppercase: true }, language(`${item}Name`))),
            React.createElement(Text, { view: 'secondary', size: 's' }, language(`${item}Description`)))));
};
