import cn from 'classnames';
import React from 'react';
import { Controls } from './controls';
import { HealthBar } from './health-bar';
import { LevelBar } from './level-bar';
import { BuildingPanelVisible } from '../../../types';
import { useBuilding } from '../../hooks/use-building';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';
import styles from './styles.module.scss';
export const BuildingPanel = ({ visible }) => {
    const building = useBuilding();
    const language = useLanguage();
    if (visible === BuildingPanelVisible.None) {
        return;
    }
    return (React.createElement("div", { className: styles.translator },
        React.createElement("div", { className: cn(styles.wrapper, {
                [styles.full]: visible === BuildingPanelVisible.Full,
            }) },
            React.createElement("div", { className: cn(styles.container, {
                    [styles.selfOwn]: building.selfOwn,
                    [styles.arrow]: visible === BuildingPanelVisible.Compact,
                }) },
                React.createElement("div", { className: styles.name }, language(`${building.schema.variant}Name`)),
                React.createElement(Section, { direction: "vertical", gap: 6 },
                    React.createElement(HealthBar, null),
                    React.createElement(LevelBar, null))),
            visible === BuildingPanelVisible.Full && (React.createElement(Controls, null)))));
};
