import { InstancedMesh } from 'three';
export class MeshSet extends InstancedMesh {
    constructor(scene, { geometry, material, children }) {
        super(geometry, material, children.length);
        children.forEach((child, index) => {
            child.put(this, index);
        });
        scene.add(this);
    }
    destroy() {
        this.removeFromParent();
        this.dispose();
    }
}
