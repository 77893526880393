import React, { useState } from 'react';
import { useEvent } from '~/client/core/event-steam/ui/hooks/use-event';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import styles from './styles.module.scss';
export const Entities = () => {
    const battle = useRoom();
    const [components, setComponents] = useState(battle.entitiesUI);
    useEvent(battle.events.onEntityChangeUI, () => {
        setComponents(new Map(battle.entitiesUI));
    }, []);
    return (React.createElement("div", { className: styles.translator }, Array.from(components.entries()).map(([entity, Component]) => !entity.disposed && (React.createElement(Component, { key: entity.renderItem.id, target: entity })))));
};
