import { Vector3 } from 'three';
import { v4 as uuidv4 } from 'uuid';
export class Utils {
    static uuid() {
        return uuidv4();
    }
    static matchDomain(domain, origin) {
        return new RegExp(`://(\\S+\\.)?${domain.replaceAll('.', '\\.')}$`).test(origin);
    }
    static clamp(value, min, max) {
        return Math.min(Math.max(value, min), max);
    }
    static randomBetween(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    static randomFloatBetween(min, max) {
        return Math.random() * (max - min) + min;
    }
    static randomItem(list) {
        if (list.length === 0) {
            return null;
        }
        const index = this.randomBetween(0, list.length - 1);
        return list[index];
    }
    static takeRandomItem(list) {
        if (list.length === 0) {
            return null;
        }
        const index = this.randomBetween(0, list.length - 1);
        const item = list[index];
        list.splice(index, 1);
        return item;
    }
    static radiansToDegrees(value) {
        return value * (180 / Math.PI);
    }
    static decorateNumber(value) {
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    static getByWeight(array, callback) {
        const target = {
            item: null,
            weight: 0,
        };
        array.forEach((item) => {
            const weight = callback(item);
            if (weight > target.weight) {
                target.weight = weight;
                target.item = item;
            }
        });
        return target.item;
    }
    static spiralSearch(startPosition, bounds, callback) {
        const currentPosition = new Vector3().copy(startPosition);
        if (callback(currentPosition, 0)) {
            return currentPosition;
        }
        let length = 1;
        let index = 1;
        const direction = new Vector3(1, 0, 0);
        while ((direction.x === 0 || length <= bounds.x * 2)
            && (direction.z === 0 || length <= bounds.z * 2)) {
            for (let i = 0; i < length; i++) {
                currentPosition.add(direction);
                if (currentPosition.x >= 0
                    && currentPosition.z >= 0
                    && currentPosition.x < bounds.x
                    && currentPosition.z < bounds.z
                    && callback(currentPosition, index)) {
                    return currentPosition;
                }
            }
            direction.set(-direction.z, 0, direction.x);
            index++;
            length = Math.ceil(index / 2);
        }
        return null;
    }
    static group(array, callback) {
        const groups = new Map();
        array.forEach((item) => {
            const key = callback(item);
            if (!key) {
                return;
            }
            let group = groups.get(key);
            if (!group) {
                group = [];
                groups.set(key, group);
            }
            group.push(item);
        });
        return groups;
    }
}
