import { Vector3 } from 'three';
import { ENTITY_INTERPOLATION_LERP } from './const';
import { Marker } from '../minimap/marker';
import { Client } from '~/client/core/client';
import { Interpolation } from '~/client/core/interpolation';
import { Logger } from '~/client/core/logger';
import { Messages } from '~/client/core/messages';
import { Prediction } from '~/client/core/prediction';
import { EntityMessage } from '~/shared/battle/entity/types';
import { EventStream } from '~/shared/core/event-stream';
import { Timeouts } from '~/shared/core/time/timeouts';
import './resources';
export class Entity {
    constructor(battle, renderItem, schema) {
        this.moving = false;
        this.velocity = new Vector3();
        this.featurePosition = new Vector3();
        this.disposed = false;
        this.timeouts = new Timeouts();
        this.prediction = null;
        this.interpolation = null;
        this.events = {
            onUpdate: new EventStream(),
        };
        this.battle = battle;
        this.renderItem = renderItem;
        this.schema = schema;
        this.selfOwn = (schema.ownerId === Client.sessionId);
        this.messages = new Messages(this.battle.origin, this.battle.messagesBuffer);
        this.messages.setChannel(this.schema.id);
        this.setPosition(schema.position);
        this.setVelocity(schema.velocity);
        this.listenSchemaPosition();
        this.listenSchemaVelocity();
        this.listenSchemaVisible();
        this.messages.on(EntityMessage.Damage, (payload) => {
            var _a;
            (_a = this.onDamage) === null || _a === void 0 ? void 0 : _a.call(this, payload);
        });
        this.schema.onRemove(() => {
            this.destroy();
        });
        this.battle.entities.set(this.schema.id, this);
        this.onSceneUpdate = this.onSceneUpdate.bind(this);
        this.battle.scene.events.onUpdate.on(this.onSceneUpdate);
        this.updateVisible();
    }
    destroy() {
        if (this.disposed) {
            Logger.warn(`Entity ${this.constructor.name} is already destroyed`);
            return;
        }
        this.renderItem.destroy();
        this.timeouts.clear();
        this.messages.clear();
        this.battle.entities.delete(this.schema.id);
        this.battle.scene.events.onUpdate.off(this.onSceneUpdate);
        this.removeUI();
        this.removeMarker();
        this.disposed = true;
        /**
         * TODO: https://github.com/neki-dev/izowave2/issues/14
         * These deletions of fields are fixing memory leak,
         * but need to find closures, which use that fields.
         */
        // @ts-ignore
        delete this.renderItem;
        // @ts-ignore
        delete this.schema;
        // @ts-ignore
        delete this.messages;
    }
    onSceneUpdate() {
        this.interpolate();
        this.timeouts.update();
        this.events.onUpdate.invoke();
    }
    setUI(ui) {
        this.battle.setEntityUI(this, ui);
    }
    removeUI() {
        this.battle.setEntityUI(this, null);
    }
    createMarker(scale = 1.0) {
        if (this.marker) {
            Logger.warn('Entity marker is already created');
            return;
        }
        this.marker = new Marker(this, scale);
    }
    removeMarker() {
        if (!this.marker) {
            return;
        }
        this.marker.destroy();
        this.marker = null;
    }
    playAudio(type) {
        this.battle.scene.audio.play3D(type, {
            parent: this.renderItem.object,
        });
    }
    stopAudio(type) {
        this.battle.scene.audio.stop(type);
    }
    enablePrediction() {
        if (this.interpolation) {
            throw Error('Unable to use prediction with interpolation');
        }
        this.prediction = new Prediction();
    }
    enableInterpolation() {
        if (this.prediction) {
            throw Error('Unable to use interpolation with prediction');
        }
        this.interpolation = new Interpolation();
        // this.interpolation.addState(this.schema.position);
        this.featurePosition.copy(this.schema.position);
    }
    updateVisible() {
        this.renderItem.setVisible(this.schema.active
            && (this.selfOwn || this.schema.visibleForOpponent));
    }
    synchronize() {
        if (this.interpolation) {
            this.featurePosition.copy(this.schema.position);
            this.setPosition(this.featurePosition);
        }
    }
    listenSchemaPosition() {
        this.schema.position.onChange(() => {
            var _a;
            if (this.interpolation) {
                // this.interpolation.addState(this.schema.position);
                this.featurePosition.copy(this.schema.position);
            }
            else {
                this.setPosition(this.schema.position);
                if (this.prediction) {
                    (_a = this.applyPrediction) === null || _a === void 0 ? void 0 : _a.call(this);
                }
            }
        });
    }
    listenSchemaVelocity() {
        this.schema.velocity.onChange(() => {
            if (this.prediction) {
                // Velocity already predicted
            }
            else {
                this.setVelocity(this.schema.velocity);
            }
        });
    }
    listenSchemaVisible() {
        this.schema.listen('active', () => {
            this.updateVisible();
        });
        if (!this.selfOwn) {
            this.schema.listen('visibleForOpponent', () => {
                this.updateVisible();
            });
        }
    }
    setPosition(position) {
        this.renderItem.setPosition(position);
    }
    setVelocity(velocity) {
        var _a;
        this.velocity.copy(velocity);
        const moving = this.velocity.lengthSq() > 0;
        if (this.moving !== moving) {
            this.moving = moving;
            (_a = this.onChangeMoveState) === null || _a === void 0 ? void 0 : _a.call(this, moving);
        }
    }
    interpolate() {
        if (!this.interpolation) {
            return;
        }
        const lerpFactor = ENTITY_INTERPOLATION_LERP * this.battle.scene.delta;
        this.renderItem.position.lerp(this.featurePosition, lerpFactor);
        // const timestamp = performance.now() - (1000 / RATE.UPDATE);
        // const position = this.interpolation.useState(timestamp);
        // if (position) {
        //   this.renderItem.position.copy(position);
        // }
    }
}
