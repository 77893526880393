import { PageType } from '../../../relay/ui/components/pages/types';
import { SettingsType } from '../../settings/types';
import { LanguageType } from '../types';
import { KeyAction } from '~/client/battle/tutorial/ui/components/hints/content/key/types';
import { SwipeDirection } from '~/client/battle/tutorial/ui/components/hints/content/swipe/types';
import { BuildingCategory, BuildingVariant } from '~/shared/battle/entity/building/types';
import { DroidVariant } from '~/shared/battle/entity/unit/npc/droid/types';
import { SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';
import { UpgradeCategory, UpgradeVariant } from '~/shared/battle/entity/unit/player/upgrades/types';
import { TutorialStep } from '~/shared/battle/tutorial/types';
import { BattleDifficult } from '~/shared/battle/types';
import { InventoryItemType } from '~/shared/core/inventory/item/types';
import { UserAuthError } from '~/shared/core/user/auth/types';
const PHRASES = {
    [PageType.Play]: 'Play',
    [PageType.Inventory]: 'Inventory',
    [PageType.Leaderboard]: 'Leaders',
    ToLogIn: 'Log In',
    ToSignUp: 'Sign Up',
    LogIn: 'Log In',
    SignUp: 'Sign Up',
    Username: 'Username',
    Password: 'Password',
    AcceptTerms: 'I accept the terms of',
    PrivacyPolicy: 'the privacy policy',
    [UserAuthError.AlreadyAuthorized]: 'You are already authorized',
    [UserAuthError.ExistUsername]: 'The specified login is taken',
    [UserAuthError.InvalidToken]: 'Invalid authorization token',
    [UserAuthError.InvalidCredentials]: 'Incorrect login or password',
    [UserAuthError.InvalidUsername]: 'Invalid login specified',
    [UserAuthError.InvalidPassword]: 'Invalid password specified',
    MainMenu: 'Main menu',
    Back: 'Back',
    Continue: 'Continue',
    Restart: 'Restart',
    Settings: 'Settings',
    RestartConfirm: 'Are you sure you want to start over?',
    LeaveConfirm: 'Are you sure you want to leave this battle?',
    LoadSave: 'Load save',
    PlayAgain: 'Play again',
    WaitingOpponent: 'Waiting the opponent...',
    OpponentDisconnected: 'The opponent left the battle',
    YouWin: 'You win',
    YouLose: 'You lose',
    YouDied: 'You died',
    [`Difficult${BattleDifficult.Easy}`]: 'Easy mode',
    [`Difficult${BattleDifficult.Easy}Description`]: 'For beginners',
    [`Difficult${BattleDifficult.Hard}`]: 'Hard mode',
    [`Difficult${BattleDifficult.Hard}Description`]: 'For skilled players',
    PlayerOnline: 'Player online',
    PlayersOnline: 'Players online',
    YouRanked: 'You are ranked',
    PlaceOf: 'place out of',
    Earn: 'Earn',
    UnlockOnlineModes: 'to unlock online modes',
    SearchingOpponent: 'Searching for\nan opponent',
    PlaySingle: 'Single play',
    PlaySingleDescription: 'Play infinitive waves mode',
    PlayOnline: 'Online play',
    PlayOnlineDescription: 'Play with a random opponent',
    PlayOnlinePrivate: 'Private play',
    PlayOnlinePrivateDescription: 'Play with your friend',
    YourId: 'Your ID',
    FriendId: 'Friend ID',
    Used: 'Used',
    Select: 'Select',
    Selected: 'Selected',
    Disable: 'Disable',
    Buy: 'Buy',
    OrHold: 'Or hold',
    Yes: 'Yes',
    No: 'No',
    On: 'On',
    Off: 'Off',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High',
    To: 'to',
    [KeyAction.Press]: 'Press',
    [KeyAction.Hold]: 'Hold',
    [`Swipe${SwipeDirection.Up}`]: 'Swipe up',
    [`Swipe${SwipeDirection.Left}`]: 'Swipe left',
    [`Hint${TutorialStep.Attack}`]: 'attack enemies',
    [`Hint${TutorialStep.UpgradeGenerator}`]: 'Tap on the generator\nto upgrade',
    [`Hint${TutorialStep.UpgradeTowerFire}`]: 'Tap on the tower\nto upgrade',
    [`Hint${TutorialStep.UpgradePlayer}`]: 'upgrade character',
    [`Hint${TutorialStep.BuildGenerator}`]: 'build generator',
    [`Hint${TutorialStep.BuildSecondGenerator}`]: 'build second generator',
    [`Hint${TutorialStep.BuildSpawnerSmall}`]: 'build spawner',
    [`Hint${TutorialStep.BuildAmmunition}`]: 'build ammunition',
    [`Hint${TutorialStep.BuildRadar}`]: 'build radar',
    [`Hint${TutorialStep.BuildTowerFire}`]: 'build fire tower',
    [`Hint${TutorialStep.BuildTowerFrost}`]: 'build frost tower',
    NotEnoughAmmo: 'Not enough ammo',
    NeedRepair: 'Need repair',
    Upgraded: 'Upgraded',
    PrepareForWave: 'Prepare for the enemy attack',
    BossWaveStarted: 'Boss wave started',
    WaveStarted: 'Wave %1 started',
    WaveCompleted: 'Wave %1 completed',
    UntilWaveStart: 'Until to\nwave start',
    EnemiesLeft: 'Enemies\nleft',
    Experience: 'Experience',
    Upgrades: 'Upgrades',
    MaxLevel: 'Max level',
    SkillDuration: 'Duration %1 s',
    SkillRecovery: 'Recovery %1 s',
    PassiveSkill: 'Passive',
    NewBuildingsAvailable: 'New buildings\navailable',
    EmptySlot: 'Empty slot',
    ExtraSlot: 'Extra slot',
    [`Category${InventoryItemType.Droid}`]: 'Droid',
    [`Category${InventoryItemType.Skill}`]: 'Skills',
    [`${DroidVariant.Combat}Name`]: 'Combat',
    [`${DroidVariant.Combat}Description`]: 'Attacks enemy mobs within a certain radius',
    [`${DroidVariant.Medic}Name`]: 'Medic',
    [`${DroidVariant.Medic}Description`]: 'Heals the character at a certain interval',
    [`${DroidVariant.Mechanic}Name`]: 'Mechanic',
    [`${DroidVariant.Mechanic}Description`]: 'Repairs damaged buildings within a certain radius',
    [`${SkillVariant.Vampire}Name`]: 'Vampirism',
    [`${SkillVariant.Vampire}Description`]: 'Restores health by 5% of damage dealt',
    [`${SkillVariant.BoostSpeed}Name`]: 'Boost speed',
    [`${SkillVariant.BoostSpeed}Description`]: 'Increases speed by 50%',
    [`${SkillVariant.InspirationDroid}Name`]: 'Inspiration droid',
    [`${SkillVariant.InspirationDroid}Description`]: 'Increases droid characteristics by 20%',
    [`${SkillVariant.InspirationMobs}Name`]: 'Inspiration mobs',
    [`${SkillVariant.InspirationMobs}Description`]: 'Increases mobs damage and speed by 10%',
    [`${SkillVariant.InspirationTowers}Name`]: 'Inspiration towers',
    [`${SkillVariant.InspirationTowers}Description`]: 'Increases towers damage and attack speed by 20%',
    [`${SkillVariant.Sacrifice}Name`]: 'Sacrifice',
    [`${SkillVariant.Sacrifice}Description`]: 'Kills your mobs and increase your health',
    [`${SkillVariant.DiscountWalls}Name`]: 'Discount walls',
    [`${SkillVariant.DiscountWalls}Description`]: 'Reduces walls cost by 50%',
    [`${SkillVariant.DamageReflection}Name`]: 'Damage reflection',
    [`${SkillVariant.DamageReflection}Description`]: 'Reflects 10% of damage taken',
    [`${SkillVariant.BoostGenerators}Name`]: 'Boost generators',
    [`${SkillVariant.BoostGenerators}Description`]: 'Increases resource generation speed by 10%',
    [`${SkillVariant.CallingMobs}Name`]: 'Calling mobs',
    [`${SkillVariant.CallingMobs}Description`]: 'Spawns mobs near character',
    [`${SkillVariant.AttackRecovery}Name`]: 'Fast attacks',
    [`${SkillVariant.AttackRecovery}Description`]: 'Increases attack recovery speed by 15%',
    [`${SkillVariant.HighDamage}Name`]: 'High damage',
    [`${SkillVariant.HighDamage}Description`]: 'Increases character damage by 15%',
    [`${SkillVariant.FrostWave}Name`]: 'Frost Wave',
    [`${SkillVariant.FrostWave}Description`]: 'Freezes enemy mobs within a certain radius',
    [BuildingCategory.Defense]: 'Defense',
    [BuildingCategory.Spawners]: 'Spawners',
    [BuildingCategory.Resources]: 'Resources',
    [BuildingCategory.Towers]: 'Towers',
    [`${BuildingVariant.Wall}Name`]: 'Wall',
    [`${BuildingVariant.Ammunition}Name`]: 'Ammunition',
    [`${BuildingVariant.Radar}Name`]: 'Radar',
    [`${BuildingVariant.Trap}Name`]: 'Trap',
    [`${BuildingVariant.Generator}Name`]: 'Generator',
    [`${BuildingVariant.SpawnerSmall}Name`]: 'Spawner',
    [`${BuildingVariant.SpawnerMedium}Name`]: 'Extra Spawner',
    [`${BuildingVariant.SpawnerLarge}Name`]: 'Ultra Spawner',
    [`${BuildingVariant.TowerFire}Name`]: 'Fire Tower',
    [`${BuildingVariant.TowerElectro}Name`]: 'Electro Tower',
    [`${BuildingVariant.TowerFrost}Name`]: 'Frost Tower',
    [`${BuildingVariant.TowerLaser}Name`]: 'Laser Tower',
    [UpgradeCategory.Main]: 'Main',
    [UpgradeCategory.Attack]: 'Attack',
    [UpgradeCategory.Build]: 'Build',
    [UpgradeVariant.MainMaxHealth]: 'Max Health',
    [UpgradeVariant.MainSpeed]: 'Movement Speed',
    [UpgradeVariant.MainDroid]: 'Droid Efficiency',
    [UpgradeVariant.AttackRecovery]: 'Attack Recovery',
    [UpgradeVariant.AttackRadius]: 'Attack Radius',
    [UpgradeVariant.AttackDamage]: 'Damage',
    [UpgradeVariant.BuildSpeed]: 'Build Speed',
    [UpgradeVariant.BuildRadius]: 'Build Radius',
    [SettingsType.FpsLimit]: 'FPS limit',
    [SettingsType.Resolution]: 'Graphic quality',
    [SettingsType.VisualEffects]: 'Visual effects',
    [SettingsType.AudioEffects]: 'Audio effects',
    [SettingsType.Fullscreen]: 'Fullscreen',
    [SettingsType.Language]: 'Language',
    [LanguageType.EN]: 'English',
    [LanguageType.RU]: 'Русский',
    [LanguageType.PT]: 'Português',
    [LanguageType.FR]: 'Français',
};
export default PHRASES;
