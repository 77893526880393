import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useScreen } from '~/client/battle/ui/hooks/use-screen';
import { useSelfPlayerSchema } from '~/client/battle/ui/hooks/use-self-player-schema';
import { ScreenType } from '~/client/battle/ui/types';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import styles from './styles.module.scss';
export const NewBuildingsAvailable = () => {
    const battle = useRoom();
    const language = useLanguage();
    const { screen } = useScreen();
    const player = useSelfPlayerSchema();
    const level = useSchemaValue(player, 'level');
    const [visible, setVisible] = useState(false);
    const refAvailables = useRef([]);
    const refPrevLevel = useRef(level);
    useEffect(() => {
        const availables = battle.builder.getAvailableBuildings();
        if (level > refPrevLevel.current &&
            availables.some((variant) => !refAvailables.current.includes(variant))) {
            setVisible(true);
        }
        refPrevLevel.current = level;
        refAvailables.current = availables;
    }, [level]);
    useEffect(() => {
        if (screen === ScreenType.Builder && visible) {
            setVisible(false);
        }
    }, [screen]);
    return (visible && !screen) && (React.createElement("div", { className: styles.text }, language('NewBuildingsAvailable')));
};
