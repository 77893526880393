import { Mob } from '../..';
import { ModelType } from '~/client/core/assets/types';
import IconHeal from './icons/heal.svg';
import './resources';
export class MobDemon extends Mob {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.MobDemon,
        }, schema);
        this.listenSchemaHealth();
    }
    listenSchemaHealth() {
        let prevHealth = this.schema.live.health;
        this.schema.live.listen('health', (health) => {
            if (health > prevHealth) {
                this.setStateIcon(IconHeal);
                this.timeouts.add(() => {
                    this.removeStateIcon();
                }, 500);
            }
            prevHealth = health;
        });
    }
}
