import { useSchemaValue } from 'colyseus-schema-hooks';
import { Client } from '~/client/core/client';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
export function useUserData(prop) {
    const room = useRoom();
    const user = room.state.users.get(Client.sessionId);
    if (!user) {
        throw Error('Undefined current user');
    }
    return useSchemaValue(user, prop);
}
