import cn from 'classnames';
import React, { useCallback } from 'react';
import { Translator } from '~/client/battle/ui/components/translator';
import { useEventValue } from '~/client/core/event-steam/ui/hooks/use-event-value';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
import IconConfirm from './icons/confirm.svg';
import IconDecline from './icons/decline.svg';
export const BuildControls = () => {
    const { builder } = useRoom();
    const variant = useEventValue(builder.events.onChangeVariant, builder.variant);
    const validState = useEventValue(builder.events.onChangeValidState, builder.validState);
    const preview = variant && builder.previewBuilding;
    const handleClickConfirm = useCallback(() => {
        builder.build();
    }, []);
    const handleClickDecline = useCallback(() => {
        builder.setVariant(null);
    }, []);
    return preview && (React.createElement(Translator, { renderItem: preview, body: React.createElement("div", { className: styles.wrapper },
            React.createElement(Interactive, { onClick: handleClickConfirm, className: cn(styles.button, styles.confirm, {
                    [styles.disabled]: !validState,
                }) },
                React.createElement(IconConfirm, null)),
            React.createElement(Interactive, { onClick: handleClickDecline, className: cn(styles.button, styles.decline) },
                React.createElement(IconDecline, null))) }));
};
