import { Vector2 } from 'three';
import { FOG_PLANE_SIZE } from '../const';
import { TERRAIN_SIZE } from '~/shared/battle/terrain/const';
export class Light {
    constructor(battle, { target, radius }) {
        this.battle = battle;
        this.target = target;
        this.setRadius(radius);
        this.battle.terrain.fog.addLight(this);
    }
    destroy() {
        this.battle.terrain.fog.removeLight(this);
    }
    setRadius(radius) {
        const size = (FOG_PLANE_SIZE.x + FOG_PLANE_SIZE.y) / 2;
        this.radius = radius / size;
    }
    getPosition() {
        return new Vector2((this.target.position.x / FOG_PLANE_SIZE.x) + ((FOG_PLANE_SIZE.x - TERRAIN_SIZE.x) / FOG_PLANE_SIZE.x) / 2, 1.0 - ((this.target.position.z / FOG_PLANE_SIZE.y) + ((FOG_PLANE_SIZE.y - TERRAIN_SIZE.z) / FOG_PLANE_SIZE.y) / 2));
    }
}
