export class Progression {
    constructor({ defaultValue, growth, maxLevel, maxValue, }) {
        this.maxLevel = null;
        this.defaultValue = defaultValue;
        if (maxLevel) {
            this.maxLevel = maxLevel;
        }
        if (maxLevel && maxValue) {
            this.growthValue = (maxValue - defaultValue) / (maxLevel - 1);
        }
        else if (growth) {
            this.growthValue = defaultValue * growth;
        }
    }
    get(level) {
        if (Number.isNaN(level) ||
            level === Infinity) {
            throw Error('Invalid progression level value');
        }
        if (this.maxLevel && level > this.maxLevel) {
            level = this.maxLevel;
        }
        const value = this.defaultValue + (this.growthValue * (level - 1));
        return value;
    }
}
