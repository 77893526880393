export var ModelType;
(function (ModelType) {
    ModelType["DroidCombat"] = "DroidCombat";
    ModelType["DroidMedic"] = "DroidMedic";
    ModelType["DroidMechanic"] = "DroidMechanic";
    ModelType["Player"] = "Player";
    ModelType["BuildingWall"] = "BuildingWall";
    ModelType["BuildingGenerator"] = "BuildingGenerator";
    ModelType["BuildingTowerFrost"] = "BuildingTowerFrost";
    ModelType["BuildingTowerFire"] = "BuildingTowerFire";
    ModelType["BuildingTowerElectro"] = "BuildingTowerElectro";
    ModelType["BuildingTowerLaser"] = "BuildingTowerLaser";
    ModelType["BuildingRadar"] = "BuildingRadar";
    ModelType["BuildingAmmunition"] = "BuildingAmmunition";
    ModelType["BuildingSpawnerSmall"] = "BuildingSpawnerSmall";
    ModelType["BuildingSpawnerMedium"] = "BuildingSpawnerMedium";
    ModelType["BuildingSpawnerLarge"] = "BuildingSpawnerLarge";
    ModelType["BuildingTrap"] = "BuildingTrap";
    ModelType["MobTurtle"] = "MobTurtle";
    ModelType["MobSpider"] = "MobSpider";
    ModelType["MobCrab"] = "MobCrab";
    ModelType["MobDemon"] = "MobDemon";
    ModelType["MobOrc"] = "MobOrc";
    ModelType["MobGolem"] = "MobGolem";
    ModelType["MobEye"] = "MobEye";
    ModelType["MobEyeChild"] = "MobEyeChild";
    ModelType["MobStingray"] = "MobStingray";
    ModelType["MobBoss"] = "MobBoss";
    ModelType["MobSiren"] = "MobSiren";
    ModelType["Tree1"] = "Tree1";
    ModelType["Tree2"] = "Tree2";
    ModelType["Mushroom1"] = "Mushroom1";
    ModelType["Mushroom2"] = "Mushroom2";
    ModelType["Rock1"] = "Rock1";
    ModelType["Rock2"] = "Rock2";
    ModelType["Rock3"] = "Rock3";
    ModelType["Rock4"] = "Rock4";
    ModelType["Crystal"] = "Crystal";
    ModelType["_Dummy"] = "_Dummy";
})(ModelType || (ModelType = {}));
