import { useRecoilValue } from 'recoil';
import { DICTIONARIES } from '../../const';
import { LanguageState } from '../state';
export function useLanguage() {
    const language = useRecoilValue(LanguageState);
    return (key, format) => {
        var _a;
        const dictionary = DICTIONARIES[language];
        let text = (_a = dictionary[key]) !== null && _a !== void 0 ? _a : key;
        if (format) {
            text = text.replace(/%\d+/g, (match) => {
                var _a;
                return ((_a = format[Number(match.slice(1)) - 1]) === null || _a === void 0 ? void 0 : _a.toString());
            });
        }
        return text;
    };
}
