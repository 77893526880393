import React from 'react';
import { useDroid } from '../../hooks/use-droid';
import { Boost } from '~/client/battle/entity/ui/components/boost';
import { Indicator } from '~/client/battle/entity/ui/components/indicator';
import { DroidMessage } from '~/shared/battle/entity/unit/npc/droid/types';
export const Indicators = () => {
    const droid = useDroid();
    return (React.createElement(Indicator, { entity: droid, messageShow: DroidMessage.Boost, messageHide: DroidMessage.Unboost },
        React.createElement(Boost, { opponent: !droid.selfOwn })));
};
