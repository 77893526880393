import { useRecoilState } from 'recoil';
import { ScreenState } from '../state';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Storage } from '~/client/core/storage';
export function useScreen() {
    const battle = useRoom();
    const [screen, setScreen] = useRecoilState(ScreenState);
    const openScreen = (type) => {
        Storage.set('Screen', type);
        setScreen(type);
        battle.togglePause(true);
    };
    const closeScreen = () => {
        Storage.remove('Screen');
        setScreen(null);
        battle.togglePause(false);
    };
    return {
        screen,
        closeScreen,
        openScreen,
    };
}
