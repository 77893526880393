import { MeshBasicMaterial, MeshLambertMaterial } from 'three';
import { TILE_COLORS } from '../../const';
import { Assets } from '~/client/core/assets';
import { TerrainBiomeType } from '~/shared/battle/terrain/types';
Object.entries(TILE_COLORS).forEach(([biome, colors]) => {
    colors.forEach((color, i) => {
        const Material = biome === TerrainBiomeType.Liquid
            ? MeshBasicMaterial
            : MeshLambertMaterial;
        Assets.addMaterial(`${biome}${i}`, new Material({
            color,
            reflectivity: 0,
        }));
    });
});
