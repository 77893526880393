import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { useSelfPlayerSchema } from '~/client/battle/ui/hooks/use-self-player-schema';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import styles from './styles.module.scss';
export const LevelPanel = () => {
    const language = useLanguage();
    const player = useSelfPlayerSchema();
    const level = useSchemaValue(player, 'level');
    const experienceProgress = useSchemaValue(player, 'experienceProgress');
    const experience = useSchemaValue(player, 'experience');
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.level, style: {
                '--progress': `${experienceProgress}%`,
            } }, level),
        React.createElement("div", { className: styles.experience },
            React.createElement("div", { className: styles.label }, language('Experience')),
            React.createElement("div", { className: styles.value }, experience))));
};
