import { UpgradeVariant } from './types';
import { Progression } from '~/shared/core/progression';
export const UPGRADE_MAX_LEVEL = 5;
export const UPGRADE_COST_GROWTH = 4.0;
export const UPGRADE_COSTS = {
    [UpgradeVariant.MainMaxHealth]: new Progression({ defaultValue: 60, growth: UPGRADE_COST_GROWTH }),
    [UpgradeVariant.MainSpeed]: new Progression({ defaultValue: 60, growth: UPGRADE_COST_GROWTH }),
    [UpgradeVariant.MainDroid]: new Progression({ defaultValue: 90, growth: UPGRADE_COST_GROWTH }),
    [UpgradeVariant.AttackRecovery]: new Progression({ defaultValue: 60, growth: UPGRADE_COST_GROWTH }),
    [UpgradeVariant.AttackRadius]: new Progression({ defaultValue: 60, growth: UPGRADE_COST_GROWTH }),
    [UpgradeVariant.AttackDamage]: new Progression({ defaultValue: 80, growth: UPGRADE_COST_GROWTH }),
    [UpgradeVariant.BuildSpeed]: new Progression({ defaultValue: 30, growth: UPGRADE_COST_GROWTH }),
    [UpgradeVariant.BuildRadius]: new Progression({ defaultValue: 30, growth: UPGRADE_COST_GROWTH }),
};
