import cn from 'classnames';
import React from 'react';
import { Score } from '~/client/core/ui/components/amount/score';
import { Waves } from '~/client/core/ui/components/amount/waves';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import styles from './styles.module.scss';
export const Leader = ({ place, name, score, waves }) => (React.createElement("div", { className: cn(styles.wrapper, styles[`place${place}`]) },
    React.createElement("div", { className: styles.place }, place),
    React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.gradient }),
        React.createElement("div", { className: styles.info },
            React.createElement(Text, { size: "l", bold: true }, name),
            React.createElement(Section, { direction: "vertical", gap: 8, className: styles.values },
                React.createElement(Score, { value: score, size: "m" }),
                React.createElement(Waves, { value: waves, size: "m", postfix: true }))))));
