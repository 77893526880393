import React from 'react';
import { Text } from '../../text';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import styles from './styles.module.scss';
export const Param = ({ type, children }) => {
    const language = useLanguage();
    return (React.createElement("div", { className: styles.container },
        React.createElement(Text, { size: "m", uppercase: true }, language(type)),
        children));
};
