import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
export const Indicator = ({ entity, messageShow, messageHide, children, onDisplay }) => {
    const [active, setActive] = useState(false);
    const refTimeout = useRef(null);
    const stopHiding = () => {
        if (refTimeout.current) {
            clearTimeout(refTimeout.current);
            refTimeout.current = null;
        }
    };
    const setHideTimeout = () => {
        refTimeout.current = setTimeout(() => {
            setActive(false);
            refTimeout.current = null;
        }, 1500);
    };
    useEffect(() => {
        if (entity.disposed) {
            return;
        }
        // @ts-ignore
        entity.messages.on(messageShow, () => {
            stopHiding();
            setActive(true);
            onDisplay === null || onDisplay === void 0 ? void 0 : onDisplay();
            if (!messageHide) {
                setHideTimeout();
            }
        });
        if (messageHide) {
            // @ts-ignore
            entity.messages.on(messageHide, () => {
                setActive(false);
            });
        }
        return () => {
            stopHiding();
        };
    }, []);
    return active && React.createElement("div", { className: styles.container }, children);
};
