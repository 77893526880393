import { RelayScene } from './scene';
import { RelayScreen } from './ui';
import { AudioType } from '~/client/core/audio/types';
import { Client } from '~/client/core/client';
import { Room } from '~/client/core/room';
import { Interface } from '~/client/core/ui';
import { RelayMessage } from '~/shared/relay/types';
export class Relay extends Room {
    constructor(room) {
        super(room);
        this.scene = new RelayScene(this);
        const unlisten = this.state.onChange(() => {
            unlisten();
            this.scene.addDroidModel();
            this.scene.addPlayerModel();
            Interface.mount(RelayScreen, this);
            Client.hideLoading();
        });
        room.onMessage(RelayMessage.JoinBattle, (id) => {
            if (document.hidden) {
                this.scene.audio.play2D(AudioType.Join);
            }
            Client.joinRoomById(id);
        });
    }
    destroy() {
        this.scene.destroy();
        super.destroy();
    }
    remountInterface() {
        Interface.unmount();
        Interface.mount(RelayScreen, this);
    }
}
