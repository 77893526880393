import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { WAVE_NOTICE_TIMEOUT } from './const';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { useNextEffect } from '~/client/core/ui/hooks/use-next-effect';
import { WAVE_SPAWN_BOSS_PER_WAVE } from '~/shared/battle/wave/const';
import styles from './styles.module.scss';
export const WaveNotice = () => {
    const { wave } = useRoom();
    if (!wave) {
        return null;
    }
    const language = useLanguage();
    const [text, setText] = useState(null);
    const going = useSchemaValue(wave.schema, 'going');
    const paused = useSchemaValue(wave.schema, 'paused');
    const refTimeout = useRef(null);
    const setHideTimeout = () => {
        refTimeout.current = setTimeout(() => {
            setText(null);
            refTimeout.current = null;
        }, WAVE_NOTICE_TIMEOUT);
    };
    useNextEffect(() => {
        if (paused) {
            return;
        }
        setText(language('PrepareForWave'));
        setHideTimeout();
    }, [paused]);
    useNextEffect(() => {
        if (going) {
            if (wave.schema.number % WAVE_SPAWN_BOSS_PER_WAVE === 0) {
                setText(language('BossWaveStarted'));
            }
            else {
                setText(language('WaveStarted', [wave.schema.number]));
            }
        }
        else {
            setText(language('WaveCompleted', [wave.schema.number - 1]));
        }
        setHideTimeout();
    }, [going]);
    useEffect(() => {
        return () => {
            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
            }
        };
    }, []);
    return text && (React.createElement("div", { className: styles.text }, text));
};
