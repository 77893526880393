import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { ControlItem } from './item';
import { OverlayState } from '../overlays/state';
import { OverlayType } from '../overlays/types';
import { Section } from '~/client/core/ui/components/section';
import styles from './styles.module.scss';
import IconDiscord from './icons/discord.svg';
import IconSettings from './icons/settings.svg';
export const Controls = () => {
    const [overlay, setOverlay] = useRecoilState(OverlayState);
    const handleClickSettings = useCallback(() => {
        const active = overlay === OverlayType.Settings;
        setOverlay(active ? null : OverlayType.Settings);
    }, [overlay]);
    return (React.createElement(Section, { direction: 'vertical', gap: 16, className: styles.wrapper },
        React.createElement(ControlItem, { icon: IconDiscord, link: "https://discord.gg/cnFAdMsRxn" }),
        React.createElement(ControlItem, { icon: IconSettings, onClick: handleClickSettings })));
};
