import { Object3D } from 'three';
import { MESH_SET_CHILD_HIDDEN_SCALE } from './const';
export class MeshSetChild {
    constructor({ position, scale, rotation }) {
        this.object = new Object3D();
        this.mesh = null;
        this.index = -1;
        this.object.position.copy(position);
        if (scale) {
            this.object.scale.set(scale, scale, scale);
        }
        if (rotation) {
            this.object.rotateY(rotation);
        }
    }
    put(mesh, index) {
        this.mesh = mesh;
        this.index = index;
        this.updateMatrix();
    }
    hide() {
        if (!this.mesh) {
            throw Error('Unable to hide invalid mesh child');
        }
        const scale = MESH_SET_CHILD_HIDDEN_SCALE;
        this.object.scale.set(scale, scale, scale);
        this.updateMatrix();
        this.mesh.instanceMatrix.needsUpdate = true;
    }
    updateMatrix() {
        if (!this.mesh) {
            throw Error('Unable to update matrix for invalid mesh child');
        }
        this.object.updateMatrix();
        this.mesh.setMatrixAt(this.index, this.object.matrix);
    }
}
