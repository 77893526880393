import React from 'react';
import { useBuilding } from '../../hooks/use-building';
import { useEventValue } from '~/client/core/event-steam/ui/hooks/use-event-value';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import styles from './styles.module.scss';
import IconAlert from './icons/alert.svg';
export const AlertLabel = () => {
    const building = useBuilding();
    const language = useLanguage();
    const labels = useEventValue(building.events.onChangeAlert, building.alerts);
    const activeLabel = labels.values().next().value;
    return activeLabel && (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.alert },
            React.createElement(Icon, { type: IconAlert, className: styles.icon })),
        React.createElement("div", { className: styles.label }, language(activeLabel))));
};
