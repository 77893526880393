import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';
import bin from './radar.bin';
import gltf from './radar.gltf';
Assets.addModel(ModelType.BuildingRadar, {
    gltf,
    bin: {
        origin: 'radar.bin',
        path: bin,
    },
});
