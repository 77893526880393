import { Crystal } from './crystal';
import { Decoration } from './decoration';
import { Fog } from './fog';
import { Tile } from './tile';
import { SceneLayer } from '../scene/types';
import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { MeshSet } from '~/client/core/render-item/mesh-set';
import { TILE_OFFSET } from '~/shared/battle/terrain/tile/const';
import { TerrainBiomeType } from '~/shared/battle/terrain/types';
import { Utils } from '~/shared/core/utils';
import './resources';
export class Terrain {
    constructor(battle) {
        this.crystals = new Set();
        this.decorationsSet = new Map();
        this.tilesSet = new Map();
        this.battle = battle;
        this.fog = new Fog(this.battle);
        this.playAmbientAudio();
        // Need to wait the updating of terrain maps to create meshes.
        // Crystals may be created only in this event, because they have a disabled flag 'tiggerAll'.
        // With the enabled flag we have a schema bug with duplicate calls of 'onAdd' event.
        this.battle.state.terrain.onChange(() => {
            this.createTiles();
            this.createDecorations();
            this.createCrystals();
        });
        this.onBattlePreparing = this.onBattlePreparing.bind(this);
        this.battle.events.onPreparing.on(this.onBattlePreparing);
    }
    destroy() {
        this.fog.destroy();
        this.destroyMeshes();
    }
    onBattlePreparing() {
        // Recreate terrain meshes on battle preparing after restart.
        this.destroyMeshes();
    }
    playAmbientAudio() {
        this.battle.scene.audio.play2D(AudioType.Ambient);
    }
    createCrystals() {
        this.battle.state.terrain.crystals.onAdd((crystal) => {
            new Crystal(this.battle, crystal);
        }, false);
    }
    createDecorations() {
        const decorations = Array.from(this.battle.state.terrain.decorations.values());
        const groups = Utils.group(decorations, Decoration.getModel);
        groups.forEach((schemas, model) => {
            this.createDecorationsMeshSet(model, schemas);
        });
    }
    createDecorationsMeshSet(model, decorations) {
        const material = Assets.getMaterial(MaterialType.Decoration);
        const geometry = Assets.getModelGeometry(model);
        if (!geometry) {
            throw Error(`Undefined geometry of decoration '${model}'`);
        }
        const children = decorations.map((schema) => (new Decoration(schema)));
        const meshSet = new MeshSet(this.battle.scene, {
            geometry,
            material,
            children,
        });
        meshSet.layers.set(SceneLayer.Misc);
        this.decorationsSet.set(model, meshSet);
    }
    createTiles() {
        const tiles = Array.from(this.battle.state.terrain.tiles.values());
        const groups = Utils.group(tiles, Tile.getGroupKey);
        groups.forEach((schemas, key) => {
            this.createTilesMeshSet(key, schemas);
        });
    }
    createTilesMeshSet(key, tiles) {
        // Every tile of set has the same biome, index and visible indexes.
        const { biome, index, visibleIndexes } = tiles[0];
        const material = Assets.getMaterial(`${biome}${index}`);
        const geometry = Tile.makeGeometry(visibleIndexes);
        const children = tiles.map((schema) => (new Tile(schema)));
        const meshSet = new MeshSet(this.battle.scene, {
            geometry,
            material,
            children,
        });
        meshSet.name = biome;
        meshSet.position.setY(TILE_OFFSET[biome]);
        switch (biome) {
            case TerrainBiomeType.Liquid: {
                meshSet.layers.set(SceneLayer.Liquid);
                break;
            }
            case TerrainBiomeType.Mounts: {
                meshSet.layers.set(SceneLayer.Mounts);
                break;
            }
            default: {
                meshSet.layers.set(SceneLayer.Ground);
                break;
            }
        }
        this.tilesSet.set(key, meshSet);
    }
    destroyMeshes() {
        this.crystals.forEach((crystal) => {
            crystal.destroy();
        });
        this.crystals.clear();
        this.decorationsSet.forEach((meshSet) => {
            meshSet.destroy();
        });
        this.decorationsSet.clear();
        this.tilesSet.forEach((meshSet) => {
            meshSet.geometry.dispose();
            meshSet.destroy();
        });
        this.tilesSet.clear();
    }
}
